import React, { useEffect, useState } from "react";
import "./AttendanceListStyle.css";
import BreadCrumbs from "../../components/globalComponents/breadCrumbs/BreadCrumbs";
// import CustomDatePicker from '../../components/globalComponents/customDatePicker/CustomDatePicker'
import ProgressBar from "../../components/globalComponents/progressBar/ProgressBar";
import { useSearchParams } from "react-router-dom";
import { lmsSliceData, getStuSessionAttendance } from "../../reducers/lmsSlice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/globalComponents/PageLoader/PageLoader";
import moment from "moment";

const AttendanceList = () => {
  const disptach = useDispatch();
  const { isFetching, singleCourseAttendance } = useSelector(lmsSliceData);
  const [searchParams] = useSearchParams();
  const layer = [
    "Attendance",
    singleCourseAttendance?.Data?.length > 0 &&
      singleCourseAttendance?.Data[0]?.CourseSession?.CourseRoster?.courseName,
  ];
  const courseId = searchParams.get("courseId") ?? "";
  const [month, setMonth] = useState(moment(new Date()).format("YYYY-MM"));

  useEffect(() => {
    let obj = {
      courseId: courseId,
      monthParam: month,
    };
    disptach(getStuSessionAttendance(obj));
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    let data = e.target.value;
    setMonth(data);
    let obj = {
      courseId: courseId,
      monthParam: data,
    };
    disptach(getStuSessionAttendance(obj));
  };

  console.log(month, "month");

  return (
    <>
      {isFetching && <PageLoader />}
      <div className="attendanceListOuter">
        <div className="pageHeaderType2">
          <div className="pageHeaderLeft">
            <BreadCrumbs layers={layer} />
          </div>
          <div className="pageHeaderRight">
            <ProgressBar
              width={`${
                singleCourseAttendance?.AttendancePercentage === null
                  ? 0
                  : singleCourseAttendance?.AttendancePercentage?.toFixed(2)
              }%`}
              color={"var(--success-4)"}
            />
            <p className="percent">
              {singleCourseAttendance?.AttendancePercentage === null
                ? 0
                : singleCourseAttendance?.AttendancePercentage?.toFixed(2)}
              %
            </p>
          </div>
        </div>

        <div className="attendanceListContainerOuter">
          <div className="attendanceListContainerHeader">
            {/* <div className="searchBox">
              <img src="../assets/icons/search-sm.svg" alt="" />
              <input
                type="text"
                className="searchInput"
                placeholder="Search ..."
              />
            </div> */}
            {/* <CustomDatePicker /> */}
            Filter by Date:
            <div className="searchBox">
            <input type="month" className="searchInput pointer" value={month} onChange={handleChange} />
            </div>
          </div>
          <div className="attendanceListContainer">
            <table
              className="attendanceListTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Session Name</th>
                  <th>Date</th>
                  <th>Attendance</th>
                </tr>
              </thead>
              <tbody>
                {singleCourseAttendance?.Data?.length > 0 &&
                  singleCourseAttendance?.Data?.map((res, i) => (
                    <tr key={i}>
                      <td>{res?.CourseSession?.name}</td>
                      <td>
                        {moment(res?.CourseSession?.date)?.format("DD-MM-YYYY")}
                      </td>
                      {res.status === "attended" ? (
                        <td>
                          <div className="primaryTag">{res.status}</div>
                        </td>
                      ) : (
                        <td>
                          <div className="dangerTag">{res.status}</div>
                        </td>
                      )}
                    </tr>
                  ))}
                {/* <tr>
                                <td>Identify your business goals.</td>
                                <td>21/7/2022</td>
                                <td><div className="dangerTag">Absent</div></td>
                            </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceList;
