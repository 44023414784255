import React, { useState, useEffect, useRef } from "react";
import "./CourseDetailStyle.css";
// import Dropdown from "../../components/globalComponents/dropdown/Dropdown";
import ProgressBar from "../../components/globalComponents/progressBar/ProgressBar";
import FileViewer from "../../components/globalComponents/fileViewer/FileViewer";
import Button from "../../components/globalComponents/buttons/primaryButton/PrimaryButton";
import QuizPopup from "../../components/popups/quizPopup/QuizPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { lmsSliceData, getCourseById, clearState2 } from "../../reducers/lmsSlice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/globalComponents/PageLoader/PageLoader";
import {
  gradeSliceFunction,
  getSpecificStudentAssignmentGrades,
  submitStudentAssignment,
  clearState,
  getAllQuiz,
} from "../../reducers/gradeSlice";
import {
  submitFeedbackAnswers,
  feedbackData,clearStateFeedback
} from "../../reducers/feedbackSlice";

import GeneralQuizPopup from "../../components/popups/generalQuizPopup/GeneralQuizPopup";
import moment from "moment";

const CourseDetail = () => {
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const { isFetching, singleCourseDetail, singleCourseFetched } = useSelector(lmsSliceData);

  const { assignmentData, isFetchingGradeSlice, isSuccess2, quizData } =
    useSelector(gradeSliceFunction);

  const { isSuccess,quizSubmitted } = useSelector(feedbackData);
  const navigate = useNavigate();

  const [toggleCourse, setToggleCourse] = useState(0);
  const [toggleCourse2, setToggleCourse2] = useState(0);
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("courseId") ?? "";
  // const [asgnClick, setAsgnClick] = useState('')
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");
  const [time, setTime] = useState(0);
  const [save, setSave] = useState("");
  const [active, setActive] = useState(false);
  const [ansArr, setAnsArr] = useState([]);
  const [index, setIndex] = useState("");
  const increment = useRef(null);
  const dec = useRef(null);
  const [viewRemarks,setViewRemarks]=useState(false)

  const [assignData, setAssignData] = useState([]);
  useEffect(() => {
    dispatch(getCourseById(courseId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (singleCourseDetail?.CourseSessions?.length > 0 && (singleCourseFetched || active)) {
      let obj = {
        courseId: courseId,
        sessionId: singleCourseDetail?.CourseSessions[toggleCourse]?.id,
        type:"session"
      };
      dispatch(getSpecificStudentAssignmentGrades(obj));
      dispatch(clearState2());
      setActive(false);
      dispatch(getAllQuiz(obj));
    }
    // eslint-disable-next-line
  }, [toggleCourse, singleCourseDetail]);

  useEffect(() => {
    let obj = {
      courseId: courseId,
      sessionId:
        singleCourseDetail?.CourseSessions?.length > 0 &&
        singleCourseDetail?.CourseSessions[toggleCourse]?.id,
    };
    if (isSuccess) {
      setShow(!show)
      dispatch(getAllQuiz(obj));
      dispatch(clearStateFeedback());

    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (index !== "") {
      setTime(data?.FeedbackQuestions[index]?.time);
      if (data?.FeedbackQuestions.length === index + 1) {
        increment.current = setInterval(function () {
          setTime((prev) => prev - 1);
        }, 1000);

        dec.current = setTimeout(function () {
          setSave("doneTimeout");
          clearInterval(increment.current);
        }, Number(data?.FeedbackQuestions[index]?.time) * 1000);
      } else {
        increment.current = setInterval(function () {
          setTime((prev) => prev - 1);
        }, 1000);

        dec.current = setTimeout(function () {
          setSave("next");
        }, Number(data?.FeedbackQuestions[index]?.time) * 1000);
      }
    }
    // eslint-disable-next-line
  }, [index]);

  useEffect(() => {
    if (save === "done") {
      handleSave(data?.id);
      setSave("");
    }
    if (save === "doneTimeout") {
      addEmptyAnswer();
      setSave("done");
    }
    if (save === "next") {
      addEmptyAnswer();
      handleBtn("text");
      setSave("");
    }
    // eslint-disable-next-line
  }, [save]);

  const addEmptyAnswer = () => {
    let obj = {
      feedbackId: data?.id,
      weightage: data?.FeedbackQuestions[index]?.weightage,
      feedbackQuestionId: data?.FeedbackQuestions[index]?.id,
      feedbackOptionId: [{ id: "", value: "" }],
      answer: "",
    };
    let idx = ansArr.findIndex(
      (val) => val.feedbackQuestionId === obj.feedbackQuestionId
    );
    if (idx === -1) {
      setAnsArr((prev) => [...prev, obj]);
    }
    return true;
  };

  const handleBtn = (id) => {
    clearInterval(increment.current);
    clearTimeout(dec.current);
    if (id === "next") addEmptyAnswer();
    setIndex((val) => val + 1);
  };

  const handleAnswer = (data, weightage) => {
    let obj = {
      feedbackId: data.feedbackId,
      weightage: weightage,
      feedbackQuestionId: data.feedbackQuestionId,
      feedbackOptionId: [{ id: data.id, value: data.value }],
      answer: "",
    };
    let idx = ansArr.findIndex(
      (val) => val.feedbackQuestionId === obj.feedbackQuestionId
    );
    if (idx === -1) {
      setAnsArr((prev) => [...prev, obj]);
    } else {
      let arr = [...ansArr];
      arr[idx] = obj;
      setAnsArr(arr);
    }
  };

  useEffect(() => {
    if (assignmentData?.length > 0) {
      const arr = [assignmentData[0]];
      setAssignData(arr);
    }
  }, [assignmentData]);
  // console.log(assignData,"1111222266666")

  const getPercentage = () => {
    let newData = singleCourseDetail?.CourseSessions;
    let allSessData =
      singleCourseDetail &&
      singleCourseDetail?.CourseSessions?.filter(
        (data) => data?.isCompleted === true
      );
    // console.log(allSessData, "singleCourseDetail");

    return newData?.length > 0
      ? Math.round(
        (allSessData?.length / singleCourseDetail?.CourseSessions?.length) *
        100
      )
      : 0;
  };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  // const handleAssignClick = (id, index) => {
  //   const data = assignmentData.filter((i) => i.id === id);
  //   // setAsgnClick(data);
  //   // setAssignData(data);
  //   setToggleCourse2(index);
  // };

  const handleSubmit = (id) => {
    const data = assignmentData.filter((i) => i.id === id);

    setAssignData(data);
    setView(true);
    setSubmit(false);
  };

  const handleView = (id) => {
    const data = assignmentData.filter((i) => i.id === id);
    setAssignData(data);
    setView(true);
    setSubmit(true);
    setViewRemarks(false)
  };
  const handleViewRemarks = (id) => {
    const data = assignmentData.filter((i) => i.id === id);
    setAssignData(data);
    setView(true);
    setSubmit(true);
    setViewRemarks(true)
  };
  const showQuizPopup = (data) => {
    setShow(true);
    setData(data);
    // setIndex(0);
  };

  // useEffect(() => {
  //   console.log(ansArr, "Question ID")
  // }, [ansArr])

  const handleSave = (id) => {
    console.log(ansArr)
    clearInterval(increment.current);
    clearTimeout(dec.current);
    setIndex("");
   
    dispatch(submitFeedbackAnswers({ feedbackId: id, answerArray: ansArr, feedbackType: data?.feedbackType }));
    setAnsArr([]);
  };

  const functionCall = (e) => {
    dispatch(submitStudentAssignment(e));
  };

  useEffect(() => {
    if (isSuccess2) {
      const courseId = searchParams.get("courseId") ?? "";
      setView(false);
      let obj = {
        courseId: courseId,
        sessionId:
          singleCourseDetail?.CourseSessions?.length > 0 &&
          singleCourseDetail?.CourseSessions[toggleCourse]?.id,
          type:"session"
      };
      dispatch(getSpecificStudentAssignmentGrades(obj));
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isSuccess2]);

  return (
    <>
      {(isFetching || isFetchingGradeSlice ||quizSubmitted ) && <PageLoader />}
      <GeneralQuizPopup
        value={view}
        onChange={setView}
        asgnId={assignData}
        func={functionCall}
        submit={submit}
        setSubmit={setSubmit}
        viewRemarks={viewRemarks}
      />
      <div className="courseDetailsOuter">
        <QuizPopup
          show={show}
          data={data}
          answer={ansArr}
          setAnswer={handleAnswer}
          setSave={setSave}
          index={index}
          setIndex={setIndex}
          time={time}
          setTime={setTime}
          handleBtn={handleBtn}
          addEmptyAnswer={addEmptyAnswer}
          setShow={setShow}

        />
        <div className="courseDetailsMenuOuter">
          <div className="sessionDetailsOuter">
            <p className="sessionName">{singleCourseDetail?.courseName}</p>
            <div className="sessionDetials">
              <p>Session {singleCourseDetail?.CourseSessions?.length}</p>
              <p>{getPercentage()}%</p>
            </div>
            <ProgressBar
              width={`${getPercentage()}%`}
              color={"var(--success-4)"}
            />
          </div>
          <h4 className="courseDetailsMenuHeading">Session Overview</h4>

          {singleCourseDetail?.CourseSessions?.length > 0 &&
            singleCourseDetail?.CourseSessions?.map((res, i) => (
              <div
                className={`sessionOverviewCard ${toggleCourse === i ? "active" : ""
                  }`}
                onClick={() => { setToggleCourse(i); setActive(true) }}
              >
                <div className="sessionCardHeader">
                  <p className="sessionHeading">{res?.name}</p>
                  <img src="../assets/icons/arrow-narrow-right.svg" alt="" />
                </div>
                <div className="currentStatus">
                  {res.isCompleted ? (
                    <img src="../assets/icons/check-circle.svg" alt="" />
                  ) : (
                    <img src="../assets/icons/pendingCircle.svg" alt="" />
                  )}

                  {res.isCompleted ? (
                    <p className="successText">Completed</p>
                  ) : (
                    <p className="secondaryText">Pending</p>
                  )}
                </div>
              </div>
            ))}

          {/* All Assignment */}
          {/* <h4 className="courseDetailsMenuHeading">All Assignment/quiz</h4> */}
          {/* {assignmentData?.map((num, index) => (
            <div className={`sessionOverviewCard ${toggleCourse2 === index ? "active" : ""
              }`} key={index} onClick={() => handleAssignClick(num.id, index)}>
              <div className="sessionCardHeader">
                <div className="sessionCardHeaderLeft">
                  <div className="sessionCardHeaderTop">
                    <p className="sessionHeading">{num.Assignment.name}</p>{" "}
                    <div className="primaryTag">General</div>
                  </div>
                  {num.assignmentStatus != "Submitted" ?
                    (<p className="sessionCardDueDate">
                      Due Date: <span>{moment(num?.Assignment?.assignmentDueDate).format("DD MMM YYYY")}</span>
                    </p>) : (<p className="sessionCardDueDate">Completed</p>)
                  }
                </div>
                <img src="../assets/icons/arrow-narrow-right.svg" alt="" />
              </div>
              {num.assignmentStatus == 'Submitted' ? <div className="currentStatus">
                <img src="../assets/icons/check-circle.svg" alt="" />
                <p className="successText">{num.assignmentStatus}</p>
              </div> : <div className="currentStatus">
                <img src="../assets/icons/pendingCircle.svg" alt="" />
                <p className='secondaryText'>{num?.assignmentStatus}</p>

              </div>}

            </div>
          ))} */}
          {/* {quizData && quizData.length>0 && quizData?.map((num, index) => (
            <div className={`sessionOverviewCard ${toggleCourse2 === num.id ? "active" : ""
              }`} key={index} onClick={() => handleAssignClick(num.id, num.id)}>
              <div className="sessionCardHeader">
                <div className="sessionCardHeaderLeft">
                  <div className="sessionCardHeaderTop">
                    <p className="sessionHeading">{num.Feedback.title}</p>{" "}
                    <div className="primaryTag">{num.Feedback.feedbackType}</div>
                  </div>
                  {num.isAttempted? (<p className="sessionCardDueDate">Completed</p>) :
                    (<p className="sessionCardDueDate">
                      Due Date: <span>{moment(num?.Feedback?.expiryDate).format("DD MMM YYYY")}</span>
                    </p>)
                  }
                </div>
                <img src="../assets/icons/arrow-narrow-right.svg" alt="" />
              </div>
              {num.isAttempted ? <div className="currentStatus">
                <img src="../assets/icons/check-circle.svg" alt="" />
                <p className="successText">Completed</p>
              </div> : <div className="currentStatus">
                <img src="../assets/icons/pendingCircle.svg" alt="" />
                <p className='secondaryText'>Pending</p>

              </div>}

            </div>
          ))} */}
        </div>
        <div className="courseContentOuter">
          <h2 className="heading">
            Session Name:{" "}
            {singleCourseDetail?.CourseSessions?.length > 0 &&
              singleCourseDetail?.CourseSessions[toggleCourse]?.name}
          </h2>
          {
            singleCourseDetail?.CourseSessions?.length > 0 && (
              // singleCourseDetail?.CourseSessions?.map((res, i) => (
              <div className="courseContentBox active">
                <div className="courseContentBoxHeader">
                  {/* <div className="tags">
                    <div className="greyTag">Session {i + 1}</div>
                  </div> */}
                  {/* <div className="toggleBtn" onClick={() => setToggleCourse(i)}>
                    {toggleCourse === i ? <p>Collapse</p> : <p>Expand</p>}
                    <img
                      src="../assets/icons/chevron-down-black.svg"
                      alt=""
                      className="toggleBtnIcon"
                    />
                  </div> */}
                </div>
                <div className="courseContentBodyHeader">
                  <p className="sessionName">
                    {singleCourseDetail?.CourseSessions[toggleCourse]?.name}
                  </p>
                  <div className="currentStatus">
                    {singleCourseDetail?.CourseSessions[toggleCourse]
                      .isCompleted ? (
                      <img src="../assets/icons/check-circle.svg" alt="" />
                    ) : (
                      <img src="../assets/icons/pendingCircle.svg" alt="" />
                    )}

                    {singleCourseDetail?.CourseSessions[toggleCourse]
                      .isCompleted ? (
                      <p className="successText">Completed</p>
                    ) : (
                      <p className="secondaryText">Pending</p>
                    )}
                  </div>
                </div>
                <div className="courseContentBoxBody">
                  <div className="videoBox">
                    {singleCourseDetail?.CourseSessions[toggleCourse]
                      ?.videoUrl === null ||
                      singleCourseDetail?.CourseSessions[toggleCourse]
                        ?.videoUrl === "" ? (
                      ""
                    ) : (
                      // <img
                      //   src="https://images.pexels.com/photos/2531608/pexels-photo-2531608.jpeg?auto=compsingleCourseDetail?.CourseSessions[toggleCourse]s&cs=tinysrgb&w=1260&h=750&dpr=1"
                      //   alt=""
                      // />
                      <video
                        className="videoBox"
                        src={
                          singleCourseDetail?.CourseSessions[toggleCourse]
                            .videoUrl
                        }
                        controlsList="nodownload"
                        disablePictureInPicture
                        controls
                        muted
                      ></video>
                    )}
                  </div>
                  <p className="sessionDescription">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: removeTags(
                          singleCourseDetail?.CourseSessions[toggleCourse]
                            .description
                        ),
                      }}
                    />
                  </p>
                  <div className="fileContainer">
                    {singleCourseDetail?.CourseSessions[toggleCourse]
                      ?.SessionFiles?.length > 0 &&
                      singleCourseDetail?.CourseSessions[
                        toggleCourse
                      ].SessionFiles?.map((data) => (
                        <FileViewer
                          fileName={data.fileName}
                          fileUrl={data.fileUrl}
                        />
                      ))}
                  </div>
                </div>
              </div>
            )
            // ))
          }
          <div className="hrLine"></div>

          <h2 className="heading">All Assignment/Quiz</h2>

          {assignmentData?.map((num, index) => (
            <div
              className={`courseContentBox ${toggleCourse2 === index ? "active" : ""
                }`}
              onClick={() => setToggleCourse2(index)}
              key={index}
            >
              <div className="courseContentBoxHeader">
                <div className="tags">
                  <div className="primaryTag">General</div>
                </div>
                <div className="toggleBtn" onClick={() => setToggleCourse2(3)}>
                  {toggleCourse2 === 3 ? <p>Collapse</p> : <p>Expand</p>}
                  <img
                    src="../assets/icons/chevron-down-black.svg"
                    alt=""
                    className="toggleBtnIcon"
                  />
                </div>
              </div>
              <div className="courseContentBodyHeader">
                <p className="sessionName">{num?.Assignment?.name}.</p>
                <div className="currentStatus">
                  {num.assignmentStatus !== "Submitted" ? (
                    <img src="../assets/icons/pendingCircle.svg" alt="" />
                  ) : (
                    <img src="../assets/icons/check-circle.svg" alt="" />
                  )}
                  <p className="secondaryText">{num?.assignmentStatus}</p>
                </div>
              </div>
              <div className="courseContentBoxBody">
                <p className="sessionDescription">
                  <div dangerouslySetInnerHTML={{ __html: removeTags(num?.Assignment.description) }} />
                </p>

                {/* {removeTags(num?.Assignment.description)} */}
                {/* </p> */}
                {/* <div className="pointerScale">
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point"></div>
                    <div className="point"></div>
                    <div className="point"></div>
                  </div> */}
                {/* {num?.grades ? <p className="points active">Grade: {num?.grades}</p> : ""} */}
                {/* <div className="hrLine"></div> */}
              </div>
              <div className="courseContentFooter">
                {/* <div className="dueDate">
                            <p>Due Date: <span>20 Oct 2022, 11:29 PM</span></p>
                        </div> */}

                <div className="submitDate">
                  {num.assignmentStatus !== "Submitted" ? (
                    <p>
                      Due Date:{" "}
                      <span>
                        {moment(num?.Assignment?.assignmentDueDate).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </p>
                  ) : (
                    "Completed"
                  )}
                </div>
                {(num.assignmentStatus === "Submitted" && num.obtainedMarks !== null) && (
                  <p className="successText">
                    Obtained Marks: {num?.obtainedMarks}/
                    {num?.Assignment?.totalPoint}
                  </p>
                )}
                {(num.assignmentStatus === "Submitted" && num.obtainedMarks === null) && (
                  <p className="dangerText">
                    Obtained Marks: Pending
                  </p>
                )}
                {
                  num?.comment !== null && 
                  <Button
                    classname={"primaryBtn"}
                    children={"View Remarks"}
                    onClick={() => handleViewRemarks(num.id)}
                  />
                }
                {num.assignmentStatus !== "Submitted" ? (
                  <Button
                    onClick={() => handleSubmit(num.id)}
                    classname={"primaryBtn"}
                    children={"Start"}
                  />
                ) : (
                  <Button
                    classname={"primaryBtn"}
                    children={"View"}
                    onClick={() => handleView(num.id)}
                  />
                )}
              </div>
            </div>
          ))}
          {quizData?.map((num, index) => (
            <div
              className={`courseContentBox ${toggleCourse2 === num.id ? "active" : ""
                }`}
              onClick={() => setToggleCourse2(num.id)}
              key={index}
            >
              <div className="courseContentBoxHeader">
                <div className="tags">
                  <div className="primaryTag">{num.Feedback.feedbackType}</div>
                </div>
                <div className="toggleBtn" onClick={() => setToggleCourse2(3)}>
                  {toggleCourse2 === 3 ? <p>Collapse</p> : <p>Expand</p>}
                  <img
                    src="../assets/icons/chevron-down-black.svg"
                    alt=""
                    className="toggleBtnIcon"
                  />
                </div>
              </div>
              <div className="courseContentBodyHeader">
                <p className="sessionName">{num?.Feedback?.title}.</p>
                <div className="currentStatus">
                  {num.isAttempted !== true ? (
                    <img src="../assets/icons/pendingCircle.svg" alt="" />
                  ) : (
                    <img src="../assets/icons/check-circle.svg" alt="" />
                  )}
                  {num.isAttempted ? (
                    <p className="successText">Completed</p>
                  ) : (
                    <p className="secondaryText">Pending</p>
                  )}
                  {/* <p className="secondaryText">{num?.assignmentStatus}</p> */}
                </div>
              </div>
              <div className="courseContentBoxBody">
                <p className="sessionDescription">
                  {/* {removeTags(num?.Assignment.description)} */}
                </p>
                {/* <div className="pointerScale">
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point active"></div>
                    <div className="point"></div>
                    <div className="point"></div>
                    <div className="point"></div>
                  </div> */}
                {/* {num?.grades ? <p className="points active">Grade: {num?.grades}</p> : ""} */}
                <div className="hrLine"></div>
              </div>
              <div className="courseContentFooter">
                {/* <div className="dueDate">
                            <p>Due Date: <span>20 Oct 2022, 11:29 PM</span></p>
                        </div> */}
                <div className="submitDate">
                  {num.isAttempted !== true ? (
                    <p>
                      Due Date:{" "}
                      <span>
                        {moment(num?.Feedback?.expiryDate).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </p>
                  ) : (
                    "Completed"
                  )}
                </div>
                {(num.isAttempted && num?.Feedback?.Assignments[0]?.StudentMarks[0]?.obtainedMarks !== null) && (
                  <p className="successText">
                    Obtained Marks: {num?.Feedback?.Assignments[0]?.StudentMarks[0]?.obtainedMarks}/
                    {num?.Feedback?.Assignments[0]?.totalPoint}
                  </p>
                )}

                {num.isAttempted !== true ? (
                  <Button
                    onClick={() => showQuizPopup(num.Feedback)}
                    classname={"primaryBtn"}
                    children={"Start"}
                  />
                ) : (
                  <>
                    <Button
                      classname={"primaryBtn"}
                      children={"View"}
                      onClick={() =>
                        navigate(`/FeedBackPreview/${num?.Feedback.id}`)
                      }
                    />
                  </>
                )}
              </div>
            </div>
          ))}

          {/* <div
            className={`courseContentBox ${toggleCourse === 4 ? "active" : ""}`}
          >
            <div className="courseContentBoxHeader">
              <div className="tags">
                <div className="successTag">Quiz</div>
                <div className="greyTag">Graded Quiz</div>
              </div>
              <div className="toggleBtn" onClick={() => setToggleCourse(4)}>
                {toggleCourse === 4 ? <p>Collapse</p> : <p>Expand</p>}
                <img
                  src="../assets/icons/chevron-down-black.svg"
                  alt=""
                  className="toggleBtnIcon"
                />
              </div>
            </div>
            <div className="courseContentBodyHeader">
              <p className="sessionName">Business goals.</p>
              <div className="currentStatus">
                <img src="../assets/icons/pendingCircle.svg" alt="" />
                <p className="secondaryText">Pending</p>
              </div>
            </div>
            <div className="courseContentBoxBody">
              <p className="sessionDescription">
                Futures Trading involves trading in contracts in the derivatives
                markets. This module covers the various intricacies involved in
                undergoing a futures trade including margins, leverages,
                pricing, etc.
              </p>
              <div className="pointerScale">
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
              </div>
              <p className="points">Points: --</p>
              <div className="hrLine"></div>
            </div>
            <div className="courseContentFooter">
              <div className="dueDate">
                <p>
                  Due Date: <span>20 Oct 2022, 11:29 PM</span>
                </p>
              </div>
              <Button classname={"secondaryBtn"} children={"Start Quiz"} />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
