import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

// let orgId;
let token;
// let adminId;
// let email;

let setTokenValues = () => {
  // orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  // adminId = localStorage.getItem("adminId");
  // email = localStorage.getItem("Email");
};

//getAllSentOfferEmailsRecord
export const getAllSentOfferEmailsRecord = createAsyncThunk(
  "emailResponseData/getAllSentOfferEmailsRecord",
  async ( params , thunkAPI) => {

  
    try {
      setTokenValues();
      var search = params?.searchTitle ?? "";
      var pageNo = params?.pageNo ?? "";
      var pageSize = params?.pageSize ?? "";
      
      const response = await fetch(
        `https://apireact.mastersunion.org/api/org/getAllSentOfferEmailsRecord?searchTitle=${search}&pageNo=${pageNo}&pageSize=${pageSize}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//updateOfferEmailStatus

export const updateOfferEmailStatus = createAsyncThunk(
  "emailResponseData/updateOfferEmailStatus",
  async ({ id, Response }, thunkAPI) => {
    setTokenValues();
    const payload = {
      isResponse: Response,
      id,
    };
    console.log(payload);
    try {
      const response = await fetch(
        environment.baseURL + `/api/org/updateOfferEmailStatus`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//deleteCandidateOfferDetails

export const deleteCandidateOfferDetails = createAsyncThunk(
  "emailResponseData/deleteCandidateOfferDetails",
  async ({ id }, thunkAPI) => {
    setTokenValues();
    const payload = {
      id,
    };
    console.log(payload);
    try {
      const response = await fetch(
        environment.baseURL + `/api/org/deleteCandidateOfferDetails`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const emailSlice = createSlice({
  name: "emailResponseData",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    emailData: [],
    emailDataCount: 0,
    isResponse: false,
    isDelete: false,
    // isSearch:false,
  },

  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },

  extraReducers: {
    [getAllSentOfferEmailsRecord.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.emailData = payload.Data;
      state.emailDataCount = payload.Count;
      state.isFetching = false;

      return state;
    },
    [getAllSentOfferEmailsRecord.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllSentOfferEmailsRecord.pending]: (state) => {
      state.isFetching = true;
    },
    [updateOfferEmailStatus.fulfilled]: (state, { payload }) => {
      state.isResponse = !state.isResponse;
      // state.isSearch = !state.isSearch;
      state.isFetching = false;
      state.isSuccess = true;

      return state;
    },
    [updateOfferEmailStatus.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateOfferEmailStatus.pending]: (state) => {
      state.isFetching = true;
    },

    [deleteCandidateOfferDetails.fulfilled]: (state, { payload }) => {
      state.isDelete = !state.isDelete;
      state.isFetching = false;
      state.isSuccess = true;

      return state;
    },
    [deleteCandidateOfferDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [deleteCandidateOfferDetails.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = emailSlice.actions;

export const emailResponseSliceData = (state) => state.emailResponseData;
