import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";



let orgId;
let token;
let studentId;
let programId;
let curTerm;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  studentId = localStorage.getItem("studentId");
  programId= localStorage.getItem("programId");
  curTerm= localStorage.getItem("curTerm");
};

// getCourseByStudentId
export const getCourseByStudentId = createAsyncThunk(
  "lmsData/getCourseByStudentId",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/program/" +
          programId +
          "/student/" +
          studentId +
          "/term/" +
          curTerm +
          "/getCourseByStudentId",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// getCourseById
export const getCourseById = createAsyncThunk(
  "lmsData/getCourseById",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/program/" +
          programId +
          "/course/" +
          obj +
          "/getCourseById",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// getStudentCourseAttendance
export const getStuCourseAttendance = createAsyncThunk(
  "lmsData/getStuCourseAttendance",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/program/" +
          programId +
          "/student/" +
          studentId +
          "/term/" +
          curTerm +
          "/getStuCourseAttendance",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// getSingleCourseAttendance
export const getStuSessionAttendance = createAsyncThunk(
  "lmsData/getStuSessionAttendance",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/course/" +
          obj.courseId +
          "/student/" +
          studentId +
          `/getStuSessionAttendance?monthParam=${obj.monthParam}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// Calander Data
export const getCourseForStudentCalendar = createAsyncThunk(
  "lmsData/getCourseForStudentCalendar",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          `/getCourseForStudentCalendar`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


// get All Elective Course
export const getAllElectiveCourse = createAsyncThunk(
  "lmsData/getAllElectiveCourse",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          "/program/" +
          programId +
          "/term/" +
          curTerm +
          `/getAllElectiveCourse`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// get All Elective Session
export const getAllElectiveSession = createAsyncThunk(
  "lmsData/getAllElectiveSession",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          `/getAllElectiveSession`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getUpcomingAssignmentsAndQuiz = createAsyncThunk(
  "lmsData/getUpcomingAssignmentsAndQuiz",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/" +
          studentId +
          "/term/" +
          curTerm +
          `/getUpcomingAssignmentsAndQuiz`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


// Bidding Course
export const addStudentBidValues = createAsyncThunk(
  "lmsData/addStudentBidValues",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          "/addStudentBidValues",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// getSpecificCourseStuAssignmentGrades

export const getSpecificCourseStuAssignmentGrades = createAsyncThunk(
  "lmsData/getSpecificCourseStuAssignmentGrades",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/org/" +
          orgId +
          "/courseId/" +
          studentId +
          "/student/" +
          studentId +
          "/type/" +
          studentId +
          "/getSpecificCourseStuAssignmentGrades",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const lmsSlice  = createSlice({
    name: 'lmsData',
    initialState: {
      isFetching: false,
      isSuccess: false,
      isError: false,
      coursesFetched:false,
      singleCourseFetched:false,
      errorMessage: "",
      getStudentCourseData:[],
      singleCourseDetail:[],
      getStudentCourseAttendance:[],
      singleCourseAttendance:[],
      calanderData:[],
      getElectiveCourse:[],
      getElectiveSession:[],
      getUpcomingAnnoucements:[],
      getCourseAssignment:[]
    },
    reducers: {
      clearState:(state)=>{
        state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
   clearState2:(state)=>{
    state.singleCourseFetched=false
    state.coursesFetched =false

   }
  },
    extraReducers:{
      [getCourseByStudentId.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.getStudentCourseData=payload
        state.coursesFetched=true
        return state;
      },
      [getCourseByStudentId.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.coursesFetched=false
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getCourseByStudentId.pending]: (state) => {
        state.isFetching = true;
      },
      [getCourseById.fulfilled]: (state, { payload }) => {
        console.log("printing payload", payload);
        state.isFetching = false;
        state.singleCourseDetail=payload
        state.singleCourseFetched=true
        return state;
      },
      [getCourseById.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getCourseById.pending]: (state) => {
        state.isFetching = true;
      },
      [getStuCourseAttendance.fulfilled]: (state, { payload }) => {
        console.log("printing payload", payload);
        state.isFetching = false;
        state.getStudentCourseAttendance=payload
        return state;
      },
      [getStuCourseAttendance.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getStuCourseAttendance.pending]: (state) => {
        state.isFetching = true;
      },
      [getStuSessionAttendance.fulfilled]: (state, { payload }) => {
        console.log("printing payload", payload);
        state.isFetching = false;
        state.singleCourseAttendance=payload
        return state;
      },
      [getStuSessionAttendance.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getStuSessionAttendance.pending]: (state) => {
        state.isFetching = true;
      },
      [getCourseForStudentCalendar.fulfilled]: (state, { payload }) => {
        console.log("printing payload", payload);
        state.isFetching = false;
        state.calanderData=payload
        return state;
      },
      [getCourseForStudentCalendar.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getCourseForStudentCalendar.pending]: (state) => {
        state.isFetching = true;
      },
      [getAllElectiveCourse.fulfilled]: (state, { payload }) => {
        console.log("printing payload123", payload);
        state.isFetching = false;
        state.getElectiveCourse=payload
        return state;
      },
      [getAllElectiveCourse.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getAllElectiveCourse.pending]: (state) => {
        state.isFetching = true;
      },
      [getUpcomingAssignmentsAndQuiz.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.getUpcomingAnnoucements=payload
        return state;
      },
      [getUpcomingAssignmentsAndQuiz.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getUpcomingAssignmentsAndQuiz.pending]: (state) => {
        state.isFetching = true;
      },
      [getAllElectiveSession.fulfilled]: (state, { payload }) => {
        console.log("printing payload", payload);
        state.isFetching = false;
        state.getElectiveSession=payload
        return state;
      },
      [getAllElectiveSession.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getAllElectiveSession.pending]: (state) => {
        state.isFetching = true;
      },
      [addStudentBidValues.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess=true
        return state;
      },
      [addStudentBidValues.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [addStudentBidValues.pending]: (state) => {
        state.isFetching = true;
      },
      [getSpecificCourseStuAssignmentGrades.fulfilled]: (state, { payload }) => {
        console.log("printing payload", payload);
        state.isFetching = false;
        state.getCourseAssignment=payload
        return state;
      },
      [getSpecificCourseStuAssignmentGrades.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getSpecificCourseStuAssignmentGrades.pending]: (state) => {
        state.isFetching = true;
      },
  }})
  

export const { clearState ,clearState2} = lmsSlice.actions;

export const lmsSliceData = (state) => state.lmsData;