import React from "react";
import "./SideMenuStyle.css";
// import $ from "jquery";
import { NavLink } from "react-router-dom";
const SideMenu = ({ isOpened, setIsOpened, setLogout }) => {
  return (
    <div className={`sideMenuOuter ${isOpened ? "active" : ""}`}>
      <div className="sideMenu">
        <ul className="sideMenuList">
          <NavLink
            onClick={() => setIsOpened(false)}
            className="sideMenuListItem"
            to={"/"}
          >
            <img src="../assets/icons/home-05.svg" alt="" />
            Dashboard
          </NavLink>
          {/* <li className="sideMenuListItem">
                    <img src="../assets/icons/bell-02.svg" alt="" />
                    Notification
                </li> */}
          {/* <li className="sideMenuListItem">
                    <img src="../assets/icons/calendar.svg" alt="" />
                    Time-Table
                </li> */}
          <NavLink
            onClick={() => setIsOpened(false)}
            className="sideMenuListItem"
            to={"/attendance"}
          >
            <img src="../assets/icons/user-check-01.svg" alt="" />
            Attendance
          </NavLink>
          <NavLink
            onClick={() => setIsOpened(false)}
            className="sideMenuListItem"
            to={"/courseAssignment"}
          >
            <img src="../assets/icons/user-check-01.svg" alt="" />
            Course Assignment
          </NavLink>
          <NavLink
            onClick={() => setIsOpened(false)}
            className="sideMenuListItem"
            to={"/announcement"}
          >
            <img src="../assets/icons/user-check-01.svg" alt="" />
            Announcement
          </NavLink>
          {/* <li className="sideMenuListItem">
                    <img src="../assets/icons/user-minus-02.svg" alt="" />
                    Leave Request
                </li> */}
          <NavLink
            onClick={() => setIsOpened(false)}
            className="sideMenuListItem"
            to={"/myCourses"}
          >
            <img src="../assets/icons/book-open-02.svg" alt="" />
            Course Roster
          </NavLink>
          <NavLink onClick={() => setIsOpened(false)} className="sideMenuListItem" to={"/grades"}>
            <img src="../assets/icons/help-octagon.svg" alt="" />
            Grades
          </NavLink>
          {/* <li className="sideMenuListItem">
                    <img src="../assets/icons/briefcase-01.svg" alt="" />
                    Career Services
                </li> */}
          {/* <li className="sideMenuListItem border-top">
                    <img src="../assets/icons/menu-01.svg" alt="" />
                    Club
                </li> */}
          {/* <li className="sideMenuListItem">
                    <img src="../assets/icons/calendar-heart-01.svg" alt="" />
                    Events
                </li> */}
          {/* <li className="sideMenuListItem">
                    <img src="../assets/icons/users-01.svg" alt="" />
                    Meeting
                </li> */}
          <NavLink onClick={() => setIsOpened(false)} className="sideMenuListItem" to={'/feedbackList'} >
            <img src="../assets/icons/message-question-square.svg" alt="" />
            Feedback
          </NavLink>
          <NavLink onClick={() => setIsOpened(false)} className="sideMenuListItem" to={"/help"}>
            <img src="../assets/icons/help-octagon.svg" alt="" />
            Help Desk
          </NavLink>
          {/* <NavLink onClick={() => setIsOpened(false)} className="sideMenuListItem" to={"/leaveList"}>
            <img src="../assets/icons/help-octagon.svg" alt="" />
            Leave List
          </NavLink> */}
          <li
            onClick={() => {
              setLogout(true);
              setIsOpened(false);
            }}
            className="sideMenuListItem border-top"
          >
            <img src="/assets/icons/log-out.svg" alt="logout" />
            Log Out
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideMenu;
