import React from 'react'
import './ProgressBarStyle.css'

const ProgressBar = ({width,color}) => {
  return (
        <div className="progessBarOuter">
            <div className="progress"style={{width:width,background:color}} ></div>
        </div>
  )
}

export default ProgressBar