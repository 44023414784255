import React, {useState } from 'react';
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton';
import CustomUploadBox from '../../../components/globalComponents/customUploadBox/CustomUploadBox';
import ErrorToast from '../../../components/Toast/ErrorToast'
import { useNavigate } from 'react-router-dom'

// import { useRef } from 'react';
// import { CSVLink, CSVDownload } from "react-csv";


const UploadFiles = () => {

	const [selectedFile, setSelectedFile] = useState(null);
	const [id, setId] = useState();
	const navigate = useNavigate();


	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const [isShowError, setIsShowError] = useState(false);
    let errMessage = "Please Upload the file"


	const handleSubmission = () => {
		const formData = new FormData();

		formData.append("file", selectedFile);

		if (id!=="") {
            console.log('success');

		}
		else {
            setIsShowError(true);

        }
		fetch(
			'https://apireact.mastersunion.org/api/org/sendEmailsToCandidates',
			{
				method: 'POST',

				headers: {
					//Accept: "application/json",
					//"Content-Type": "multipart/form-data",
					//Authorization: token,
				},
				body: formData,

			}
		)
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	// const CsvData = async () => {

	// 	try {
	// 		const response = await fetch('http://localhost:2001/api/org/downloadSampleOfferEmails', {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 			},
	// 		}
	// 		);
	// 		if (!response.ok) {
	// 			throw new Error(`Error! status: ${response.status}`);
	// 		}

	// 		const result = await response.json();
	// 		console.log('result is: ', JSON.stringify(result, null, 4));

	// 		setData(result);
	// 	} catch (err) {
	// 		setErr(err.message);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	// console.log(data);

	const openInNewTab = url => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};



	return (
		<>
         <ErrorToast show={isShowError}
                setShow={() => setIsShowError(false)}
                message={errMessage} />
			 <div className="pageHeader">
                <div className="backBtn"  onClick={() => navigate('/emailResponse')} >
                    <img src="../assets/icons/back-btn.svg" alt=""/>
                    Go To
                </div>
                <div></div>
                <div></div>
            </div>	
		<div className='emailToCandidateContianerOuter'>
			<div className='emailToCandidateContainer'>
				<div className='emailToCandidateUploadBox'>
					<input type="file" name="file" accept={".csv"} onChange={changeHandler} />
					<PrimaryButton isdisabled={selectedFile!== null?false:true} classname={'primaryBtnSmall'} children={'Upload File'} onClick={handleSubmission} />
				</div>
				<PrimaryButton classname={'secondaryBtnSmall'} children={'Download Sample File'} onClick={() => openInNewTab('https://apireact.mastersunion.org/api/org/downloadSampleOfferEmails')} />
				{/* <a href="http://localhost:2001/api/org/downloadSampleOfferEmails" target="_blank" rel="noopener noreferrer">
					Download
				</a> */}
			</div>
		</div>

		</>

	)
}

export default UploadFiles;