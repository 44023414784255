import React, { useEffect } from "react";

const ErrorToast = ({ show, setShow, message }) => {
  useEffect(() => {
    setTimeout(() => {
      setShow();
    }, 2000);
    // eslint-disable-next-line
  }, [show]);
  return (
    <div className={`danger-toast ${show ? "active" : ""}`}>
      <img src="../assets/icons/errorIcon.svg" alt="" />
      <p>{message}</p>
    </div>
  );
};

export default ErrorToast 
