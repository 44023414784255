import React from "react";
import "./StudentCvPopupStyle.css";
const StudentCvPopup = () => {
  return (
    <div className="popupOuter">
      <div className="cvPopupContainer">
        <div className="popupHeader">
          <div className="popupHeaderLeft">
            <p className="title">CV Details</p>
          </div>
          <img src="../assets/icons/x-close.svg" alt="" />
        </div>
        <div className="popupBody">
          <div className="studentpopupHeader">
            <img
              src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
              className="studentImg"
            />
            <div className="studentContent">
              <div className="studentContentHeader">
                <p className="studentName">Utkarsh Rathore</p>
                <div className="successTag">PGP-TBM Cohort’ 22</div>
                <div className="primaryTag">Term 1</div>
              </div>
              <div className="studentBasicInfoOuter">
                <div className="studentBasicInfo">
                  <p className="heading">Date of Birth</p>
                  <p className="content">6 july 2001</p>
                </div>
                <div className="studentBasicInfo">
                  <p className="heading">Gender</p>
                  <p className="content">Male</p>
                </div>
                <div className="studentBasicInfo">
                  <p className="heading">Mobile Number</p>
                  <p className="content">0987654321</p>
                </div>
                <div className="studentBasicInfo">
                  <p className="heading">Email</p>
                  <p className="content">utkarsg@gmail.com</p>
                </div>
                <div className="studentBasicInfo">
                  <p className="heading">City</p>
                  <p className="content">Lucknow</p>
                </div>
                <div className="studentBasicInfo">
                  <p className="heading">State</p>
                  <p className="content">Uttar Pradesh</p>
                </div>
                <div className="studentBasicInfo">
                  <p className="heading">Linkdin</p>
                  <p className="content primaryText pointer">utkarsh.rathore</p>
                </div>
                <div className="studentBasicInfo">
                  <p className="heading">CV Details</p>
                  <img
                    src="https://www.shutterstock.com/image-vector/professional-resume-template-vector-design-600w-1930790453.jpg"
                    alt=""
                    className="cvImg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCvPopup;
