import React from 'react'
import './CheckboxStyle.css'
const Checkbox = ({label,checked,onChange}) => {
    return (
        <>
           <div className="checkmarkContainerOuter">
           <label class="checkboxContainer">
                <input type="checkbox" checked={checked} onChange={onChange} />
                <span class="checkmark"></span>
                {label}
            </label>
           </div>
        </>
    )
}

export default Checkbox