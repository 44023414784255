import React, { useState } from "react";
import "./GeneralQuizPopupStyle.css";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import { useSelector } from "react-redux";
import CustomUploadBox from "../../globalComponents/customUploadBox/CustomUploadBox";
import { gradeSliceFunction } from "../../../reducers/gradeSlice";
import PageLoader from "../../globalComponents/PageLoader/PageLoader";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as filestack from "filestack-js";
import TextField from "../../globalComponents/textFields/TextField";

const client = filestack.init("AXgXGi3jhSyiQo8NOKNfWz");

const GeneralQuizPopup = ({
  value,
  onChange,
  asgnId,
  func,
  submit,
  setSubmit,viewRemarks
}) => {
  const [answer, setAnswer] = useState("");
  const [load, setLoad] = useState(false);
  const [url, setUrl] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const token = {};

  const handleSubmit = () => {
    const obj = {
      assignmentId: asgnId[0]?.Assignment.id,
      submitAnswer: (answer && answer) || (url && url),
      assignmentStatus: "Submitted",
    };

    func(obj);
  };

  const { isFetching2 } = useSelector(gradeSliceFunction);

  function removeTags(str) {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const handleRemove = () => {
    setUrl("");
    setSelectedKey("");
  };

  const handleUpload = (event) => {
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      setLoad(true);
      client.upload(files, {}, token).then((res) => {
        if (res) {
          setSelectedKey(res.name);
          setUrl(res.url);
          setLoad(false);
        } else {
          setLoad(false);
        }
      });
    }
  };

  return (
    <>
      {(isFetching2 || load) && <PageLoader />}
      {
        viewRemarks?
        
      <div className={`popupOuter ${value ? "active" : ""}`}>
      <div className="popupContainer generalQuestionContainer">
        <div className="popupHeader">
          <p className="popupTitle">Assignment Remarks</p>
          <img
            onClick={() => {
              onChange(false);
              setAnswer("");
              setSubmit(false);
            }}
            src="/assets/icons/x-close.svg"
            alt=""
          />
        </div>
        <div className="popupBody">
          {/* <p className="heading"> {asgnId[0]?.Assignment.name}</p> */}
          <p className="question">
           {asgnId[0]?.comment}
          </p>
        </div>
      </div>
    </div>
        :
        <div className={`popupOuter ${value ? "active" : ""}`}>
          <div className="popupContainer generalQuestionContainer">
            <div className="popupHeader">
              <p className="popupTitle">Assignment</p>
              <img
                onClick={() => {
                  onChange(false);
                  setAnswer("");
                  setSubmit(false);
                }}
                src="/assets/icons/x-close.svg"
                alt=""
              />
            </div>
            <div className="popupBody">
              <p className="heading"> {asgnId[0]?.Assignment.name}</p>
              <p className="question">
                <div
                  dangerouslySetInnerHTML={{
                    __html: removeTags(asgnId[0]?.Assignment.description),
                  }}
                />
                {}
              </p>
            </div>
            {submit === true ? (
              <>
                <div className="popupBody">
                  {asgnId[0]?.Assignment?.assignmentSubmissionType ===
                    "Website URL" ||
                  asgnId[0]?.Assignment?.assignmentSubmissionType ===
                    "File Uploads" ? (
                    <div className="assignmentDownloadBtn">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="secondaryBtnSmall"
                        href={removeTags(asgnId[0]?.submitAnswer)}
                      >
                        Download
                      </a>
                    </div>
                  ) : (
                    <>{removeTags(asgnId[0]?.submitAnswer)}</>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="popupBody">
                  {asgnId[0]?.Assignment?.assignmentSubmissionType ===
                  "File Uploads" ? (
                    <CustomUploadBox
                      label={"Upload file"}
                      onChange={handleUpload}
                      value={selectedKey}
                      handleRemove={handleRemove}
                      // accept={"video/mp4,video/x-m4v,video/*"}
                    />
                  ) : asgnId[0]?.Assignment?.assignmentSubmissionType ===
                    "Website URL" ? (
                    <TextField
                      inputType={"url"}
                      onChange={(e) => setAnswer(e.target.value)}
                      value={answer}
                      placeholder={"Paste your URL"}
                    />
                  ) : (
                    <SunEditor
                      height="280"
                      onChange={setAnswer}
                      setContents={answer}
                      setOptions={{
                        buttonList: [
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "list",
                            "align",
                            "fontSize",
                            "formatBlock",
                            "fullScreen",
                          ],
                        ],
                      }}
                    />
                  )}
                </div>
                <div className="popupFooter flexEnd">
                  <PrimaryButton
                    onClick={handleSubmit}
                    classname={"primaryBtn"}
                    children={"Submit"}
                    isdisabled={false}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default GeneralQuizPopup;
