import React from "react";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import "./LogoutPopupStyle.css";
const LogoutPopup = ({
  title,
  boldText,
  text,
  btnType,
  btnText,
  isOpen,
  onClose,
  onLogout,
}) => {
  return (
    <div className={`popupOuter ${isOpen ? "active" : ""}`}>
      <div className="confirmationPopupContainer">
        <div className="popupHeader">
          <p className="popupTitle">{title}</p>
        </div>
        <div className="popupBody">
          <p className="popupText-logout">
            <b>{boldText}</b>
          </p>
          <div className="popupBtnGrpNew-Logout">
            <PrimaryButton
              onClick={onClose}
              classname={btnType}
              children={"No"}
            />
            <PrimaryButton
              onClick={onLogout}
              classname={"dangerBtn"}
              children={"Logout"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutPopup;
