import React, { useEffect, useState } from "react";
import './SingleSelectStyle.css';

const SingleSelect = (props) => {
    const [checkedValue, setValueChange] = useState("");

    const handleOnChangeInput = (e) => {
        setValueChange(e.target.id);
        props.onSelect([{ id: e.target.id, value: e.target.value }]);
    };

    useEffect(() => {
        if (props.value) {
            setValueChange(props.value.feedbackOptionId[0].id);
        }
    }, [props?.value]);

    return (
        <div className="singleselect mt-30 col-3">
            {props.item.option.map((item, index) => (
                <label
                    key={index}
                    className={
                        checkedValue === item.id
                            ? "singleselectlabel active"
                            : "singleselectlabel"
                    }
                >
                    <input
                        // onClick={() => setActive(0)}
                        onChange={handleOnChangeInput}
                        className="singleselect-btn"
                        type="radio"
                        id={item.id}
                        checked={checkedValue === item.id}
                        value={item.value}
                    />
                    <span className="singleselectradio" />
                    {item.value}
                </label>
            ))}
        </div>
    );
};

export default SingleSelect