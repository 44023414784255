import React from "react";
import "./customUploadBoxStyle.css";

const CustomUploadBox = ({ label, onChange, value, handleRemove, accept,newValue }) => {
  return (
    // <div className="upload-box-inner">
    //     <label htmlFor="upload-box">
    //         <span className='upload-btn'>Choose File</span>
    //         No File Chosen
    //     </label>
    //     <input type="file" id='upload-box'/>

    //     <div class="upload-box-icons">
    //         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 1C6.25 0.585786 6.58579 0.25 7 0.25H13C13.4142 0.25 13.75 0.585786 13.75 1C13.75 1.41421 13.4142 1.75 13 1.75H7C6.58579 1.75 6.25 1.41421 6.25 1ZM2.99417 3.25H1C0.585786 3.25 0.25 3.58579 0.25 4C0.25 4.41421 0.585786 4.75 1 4.75H2.29834L2.955 14.6C3.00586 15.363 3.04696 15.9796 3.12022 16.4786C3.19579 16.9932 3.31292 17.4431 3.54831 17.8563C3.92345 18.5147 4.48928 19.0441 5.17126 19.3746C5.59919 19.582 6.0558 19.6689 6.57438 19.7101C7.07707 19.75 7.6951 19.75 8.45975 19.75H11.5402C12.3049 19.75 12.9229 19.75 13.4256 19.7101C13.9442 19.6689 14.4008 19.582 14.8287 19.3746C15.5107 19.0441 16.0766 18.5147 16.4517 17.8563C16.6871 17.4431 16.8042 16.9932 16.8798 16.4786C16.953 15.9796 16.9941 15.363 17.045 14.6L17.7017 4.75H19C19.4142 4.75 19.75 4.41421 19.75 4C19.75 3.58579 19.4142 3.25 19 3.25H17.0058C17.0014 3.24996 16.9971 3.24996 16.9927 3.25H3.00732C3.00294 3.24996 2.99856 3.24996 2.99417 3.25ZM16.1983 4.75H3.80166L4.44963 14.4694C4.50303 15.2704 4.54072 15.8276 4.60431 16.2607C4.66657 16.6847 4.74617 16.9286 4.85165 17.1138C5.07673 17.5088 5.41623 17.8265 5.82542 18.0248C6.01718 18.1177 6.26585 18.1809 6.69307 18.2148C7.12943 18.2495 7.68783 18.25 8.49065 18.25H11.5093C12.3122 18.25 12.8706 18.2495 13.3069 18.2148C13.7342 18.1809 13.9828 18.1177 14.1746 18.0248C14.5838 17.8265 14.9233 17.5088 15.1483 17.1138C15.2538 16.9286 15.3334 16.6847 15.3957 16.2607C15.4593 15.8276 15.497 15.2704 15.5504 14.4694L16.1983 4.75ZM8 7.75C8.41421 7.75 8.75 8.08579 8.75 8.5V13.5C8.75 13.9142 8.41421 14.25 8 14.25C7.58579 14.25 7.25 13.9142 7.25 13.5V8.5C7.25 8.08579 7.58579 7.75 8 7.75ZM12 7.75C12.4142 7.75 12.75 8.08579 12.75 8.5V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V8.5C11.25 8.08579 11.5858 7.75 12 7.75Z" fill="#DF2935"/>
    //         </svg>
    //     </div>

    // </div>
    <div className="uploadBoxOuter">
      <p className="label">{label}</p>
      <div className="upload-box-inner">
        <label htmlFor="upload-box">
          <span className="upload-btn">Choose File</span>
          {value === "" ? "No File Chosen" : value}
        </label>
        <input
          type="file"
          id="upload-box"
          onChange={onChange}
          accept={accept}
          value={newValue}
        />

        <div class="upload-box-icons active">
          <img
            src="../assets/icons/trash-01.svg"
            alt=""
            onClick={handleRemove}
          />
          {/* <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <circle cx="15" cy="15" r="15" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 9.1875C15.3107 9.1875 15.5625 9.43934 15.5625 9.75V14.4375H20.25C20.5607 14.4375 20.8125 14.6893 20.8125 15C20.8125 15.3107 20.5607 15.5625 20.25 15.5625H15.5625V20.25C15.5625 20.5607 15.3107 20.8125 15 20.8125C14.6893 20.8125 14.4375 20.5607 14.4375 20.25V15.5625H9.75C9.43934 15.5625 9.1875 15.3107 9.1875 15C9.1875 14.6893 9.43934 14.4375 9.75 14.4375H14.4375V9.75C14.4375 9.43934 14.6893 9.1875 15 9.1875Z" fill="#262626" />
                    </svg> */}
        </div>
      </div>
    </div>
  );
};

export default CustomUploadBox;
