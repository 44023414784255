import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "../userSlice";
import { lmsSlice } from "../lmsSlice";
import { leaveSlice } from "../leaveSlice";
import { feeSlice } from "../feeSlice";
import { announcementSlice } from "../announcementSlice";
import { feedbackSlice } from "../feedbackSlice";
import { emailSlice } from "../emailSlice";
import { gradeSlice } from "../gradeSlice";
import { helpDeskSlice } from "../helpDeskSlice";
import { interviewSlice } from "../interviewSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    lmsData: lmsSlice.reducer,
    feeDetails: feeSlice.reducer,
    announcement: announcementSlice.reducer,
    feedback: feedbackSlice.reducer,
    leave: leaveSlice.reducer,
    helpDeskData: helpDeskSlice.reducer,
    grades: gradeSlice.reducer,
    emailResponseData: emailSlice.reducer,
    interview: interviewSlice.reducer,
  },
});
