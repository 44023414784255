import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Bankloan from "./Bankloan";
import Banktransfer from "./Banktransfer";

const FeePaymentType = (props) => {
  // const navigate = useNavigate();
  const [bankTransfer, setBankTransfer] = useState(false);
  const [bankloan, setBankloan] = useState(false);

  const onClickPayOnline = () => {
    props.setClose();
    window.open(props.paymentLink);
  };

  const handleBankTransferOpen = () => {
    setBankTransfer(!bankTransfer);
  };

  return (
    <>
      <Banktransfer
        feeDueId={props.feeDueId}
        isOpen={bankTransfer}
        setOpen={handleBankTransferOpen}
      />

      <Bankloan isOpen={bankloan} setOpen={setBankloan} />

      <div
        className={`popupOuter feePopup ${props.isShow ? "active" : ""}`}
      >
        <div className="popupContainer">
          <div className="popupHeader">
            <p className="popupTitle">Payment Type</p>
            <img
              src="../assets/icons/x-close.svg"
              onClick={() => props.setClose(false)}
              alt=""
              className="close-pointer"
            />
          </div>

          <div className="popupBody">
            <div className="payment-type-wrapper">
              <div
                className="payment-type active"
                onClick={() => onClickPayOnline()}
              >
                Pay Online
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.97974 3.64645C9.175 3.45118 9.49158 3.45118 9.68685 3.64645L13.6868 7.64645C13.8821 7.84171 13.8821 8.15829 13.6868 8.35355L9.68685 12.3536C9.49158 12.5488 9.175 12.5488 8.97974 12.3536C8.78448 12.1583 8.78448 11.8417 8.97974 11.6464L12.1262 8.5H2.66663C2.39048 8.5 2.16663 8.27614 2.16663 8C2.16663 7.72386 2.39048 7.5 2.66663 7.5H12.1262L8.97974 4.35355C8.78448 4.15829 8.78448 3.84171 8.97974 3.64645Z"
                    fill="#262626"
                  />
                </svg>
              </div>

              <div
                className="payment-type"
                onClick={() => {
                  setBankTransfer(true);
                  props.setClose(false);
                }}
              >
                Bank Transfer
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.97974 3.64645C9.175 3.45118 9.49158 3.45118 9.68685 3.64645L13.6868 7.64645C13.8821 7.84171 13.8821 8.15829 13.6868 8.35355L9.68685 12.3536C9.49158 12.5488 9.175 12.5488 8.97974 12.3536C8.78448 12.1583 8.78448 11.8417 8.97974 11.6464L12.1262 8.5H2.66663C2.39048 8.5 2.16663 8.27614 2.16663 8C2.16663 7.72386 2.39048 7.5 2.66663 7.5H12.1262L8.97974 4.35355C8.78448 4.15829 8.78448 3.84171 8.97974 3.64645Z"
                    fill="#262626"
                  />
                </svg>
              </div>
              {/* 
              <div
                className="payment-type"
                onClick={() => {
                  setBankloan(true);
                  props.setClose(false);
                }}
              >
                Apply for Bank Loan
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.97974 3.64645C9.175 3.45118 9.49158 3.45118 9.68685 3.64645L13.6868 7.64645C13.8821 7.84171 13.8821 8.15829 13.6868 8.35355L9.68685 12.3536C9.49158 12.5488 9.175 12.5488 8.97974 12.3536C8.78448 12.1583 8.78448 11.8417 8.97974 11.6464L12.1262 8.5H2.66663C2.39048 8.5 2.16663 8.27614 2.16663 8C2.16663 7.72386 2.39048 7.5 2.66663 7.5H12.1262L8.97974 4.35355C8.78448 4.15829 8.78448 3.84171 8.97974 3.64645Z"
                    fill="#262626"
                  />
                </svg>
              </div>

              <div className="payment-type">
                Apply for Masters’ Union Loan
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.97974 3.64645C9.175 3.45118 9.49158 3.45118 9.68685 3.64645L13.6868 7.64645C13.8821 7.84171 13.8821 8.15829 13.6868 8.35355L9.68685 12.3536C9.49158 12.5488 9.175 12.5488 8.97974 12.3536C8.78448 12.1583 8.78448 11.8417 8.97974 11.6464L12.1262 8.5H2.66663C2.39048 8.5 2.16663 8.27614 2.16663 8C2.16663 7.72386 2.39048 7.5 2.66663 7.5H12.1262L8.97974 4.35355C8.78448 4.15829 8.78448 3.84171 8.97974 3.64645Z"
                    fill="#262626"
                  />
                </svg>
              </div>

              <div className="payment-type">
                Partial Payment
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.97974 3.64645C9.175 3.45118 9.49158 3.45118 9.68685 3.64645L13.6868 7.64645C13.8821 7.84171 13.8821 8.15829 13.6868 8.35355L9.68685 12.3536C9.49158 12.5488 9.175 12.5488 8.97974 12.3536C8.78448 12.1583 8.78448 11.8417 8.97974 11.6464L12.1262 8.5H2.66663C2.39048 8.5 2.16663 8.27614 2.16663 8C2.16663 7.72386 2.39048 7.5 2.66663 7.5H12.1262L8.97974 4.35355C8.78448 4.15829 8.78448 3.84171 8.97974 3.64645Z"
                    fill="#262626"
                  />
                </svg>
              </div> */}
            </div>
          </div>

          <div className="popupFooter">
            <p className="footer-text">
              View Taxes for Online Payment Method I Refund Policy
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeePaymentType;
