import React, { useEffect, useState } from 'react';
import './RatingStyle.css';


const Rating = (props) => {
  const [rating, setRating] = useState("0");

  const handleClick = (val) => { 

      setRating(val);
      props.onSelect(val);
  }

  useEffect(() => {
      if (props.value) {
          setRating(props.value.answer);
      }
  },[props?.value]);

  return (
      <div className='rating-wrapper mt-30'>
          <div className="rating">
              <input type="radio" name={props.item.id} checked={rating === "5"} id={props.item.id + '1'}  onChange={() => handleClick("5")} />
              <label htmlFor={props.item.id + '1'}></label>
              <input type="radio" name={props.item.id} checked={rating === "4"} id={props.item.id + '2'}  onChange={() => handleClick("4")} />
              <label htmlFor={props.item.id + '2'}></label>
              <input type="radio" name={props.item.id} checked={rating === "3"} id={props.item.id + '3'}  onChange={() => handleClick("3")} />
              <label htmlFor={props.item.id + '3'}></label>
              <input type="radio" name={props.item.id} checked={rating === "2"} id={props.item.id + '4'}  onChange={() => handleClick("2")} />
              <label htmlFor={props.item.id + '4'}></label>
              <input type="radio" name={props.item.id} checked={rating === "1"} id={props.item.id + '5'}  onChange={() => handleClick("1")} />
              <label htmlFor={props.item.id + '5'}></label>
          </div>
          <div className="rating-count">
              {rating} out of 5
          </div>
      </div>
  )
}

export default Rating