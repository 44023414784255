import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let studentId;

//Api-> /org/:organizationId/:studentId/getAllAnnouncements

let setTokenValues = () => {
    orgId = localStorage.getItem("orgId");
    token = localStorage.getItem("token");
    studentId = localStorage.getItem("studentId");
};

//getAllAnnouncements
export const getAllAnnouncements = createAsyncThunk(
    "announcement/getAllAnnouncements",
    async (obj, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                environment.baseURL +
                "/api/std/org/" +
                orgId +
                "/" +
                studentId +
                "/getAllAnnouncements",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();

            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const announcementSlice = createSlice({
    name: 'announcement',
    initialState: {
        announcementData: [],
        announcementFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
    },
    reducers: {
        clearState:(state)=>{
          state.isError = false;
        state.isSuccess = false;
        state.announcementFetching = false;
        return state;
      }},
    extraReducers:{
        [getAllAnnouncements.fulfilled]: (state, { payload }) => {
            console.log("printing payload", payload);
            state.announcementFetching = false;
            state.announcementData = payload
            return state;
          },
          [getAllAnnouncements.rejected]: (state, { payload }) => {
            state.announcementFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
          },
          [getAllAnnouncements.pending]: (state) => {
            state.announcementFetching = true;
          }
    }
})

export const { clearState } = announcementSlice.actions;
export const announcementSliceFunction = (state) => state.announcement;