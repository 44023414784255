import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

// let orgId;
let token;
let studentId;
let term;

// /:studentId/course/:courseId/getStudentCourseAssignmentsDetails
// /:studentId/term/:term/getAllStudentCoursesByTerm

let setTokenValues = () => {
  // orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  studentId = localStorage.getItem("studentId");
  term = localStorage.getItem("curTerm")
};

export const getAllStudentCoursesByTerm = createAsyncThunk(
  "grades/getAllStudentCoursesByTerm",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        studentId +
        "/term/" +
        term +
        "/getAllStudentCoursesByTerm",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllQuiz = createAsyncThunk(
  "grades/getAllQuiz",
  async (obj,thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/student/" + 
        studentId +
        "/session/" +
        obj.sessionId+
        "/getStudentQuiz",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
///course/:courseId/student/:studentId/getSpecificStudentAssignmentGrades

export const getSpecificStudentAssignmentGrades = createAsyncThunk(
  "grades/getSpecificStudentAssignmentGrades",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/course/" + obj.courseId +
        "/student/" + studentId + 
        "/session/" + obj.sessionId +"/type/"+obj.type + "/getSpecificStudentAssignmentGrades",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }

)

export const getSpecificStudentAssignmentGradesAdmin = createAsyncThunk(
  "grades/getSpecificStudentAssignmentGradesAdmin",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
     
      const response = await fetch(
        environment.baseURL +
        "/api/std/course/" + id + "/student/" + studentId + "/getSpecificStudentAssignmentGradesAdmin",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

// /:studentId/assignment/:assignmentId/submitStudentAssignment
export const submitStudentAssignment = createAsyncThunk(
  "grades/submitStudentAssignment",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      var Id = obj.assignmentId;
      const payload = {
        studentId: studentId,
        assignmentId: obj.assignmentId,
        assignmentStatus: obj.assignmentStatus,
        submitAnswer: obj.submitAnswer
      }
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        studentId +
        "/assignment/" +
        Id +
        "/submitStudentAssignment",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
)

export const gradeSlice = createSlice({
  name: 'grades',
  initialState: {
    courseData: [],
    quizData:[],
    assignmentData: [],
    gradeData:[],
    isFetching: false,
    isSuccess2: false,
    isError2 : false,
    isFetching2 : false,
    isError: false,
    errorMessage: "",
    isFetchingGradeSlice: false,
    studentName:"",
    isDone:false

  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess2 = false;
      state.isError2 = false;
      state.isFetching2 = false;
      state.isFetching = false;
      state.isFetchingGradeSlice= false;
      state.isDone= false
      return state;
    }
  },
  extraReducers: {
    [getAllStudentCoursesByTerm.fulfilled]: (state, { payload }) => {
      console.log("Printing payload-getAllStudentCoursesByTerm", payload);
      state.isFetching = false;
      state.courseData = payload;
      state.isDone = true;
      return state;
    },
    [getAllStudentCoursesByTerm.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllStudentCoursesByTerm.pending]: (state) => {
      state.isFetching = true;
    },
    [getSpecificStudentAssignmentGrades.fulfilled]: (state, { payload }) => {
      // console.log("Printing payload-getSpecificStudentAssignmentGrades", payload);
      state.isFetching = false;
      state.isFetchingGradeSlice= false;
      state.assignmentData = payload.Data;
      state.studentName = payload.studentData
      return state;
    },
    [getSpecificStudentAssignmentGrades.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isFetchingGradeSlice= false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificStudentAssignmentGrades.pending]: (state) => {
      state.isFetching = true;
      state.isFetchingGradeSlice= true;
    },
    [submitStudentAssignment.fulfilled]: (state, { payload }) => {
      // state.createdSuccess = true;
      state.isSuccess2 = true;
      console.log("printing payload-submitStudentAssignment", payload);
      state.isFetching2 = false;
      return state;
    },
    [submitStudentAssignment.rejected]: (state, { payload }) => {
      console.log("payload rejected", payload);
      state.isFetching2 = false;
      state.isError2 = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [submitStudentAssignment.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching2 = true;
    },
    [getAllQuiz.fulfilled]: (state, { payload }) => {
      // console.log("Printing payload-getAllStudentCoursesByTerm", payload);
      state.isFetching = false;
      state.isFetchingGradeSlice= false;
      state.quizData = payload;
      return state;
    },
    [getAllQuiz.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isFetchingGradeSlice= false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllQuiz.pending]: (state) => {
      state.isFetching = true;
      state.isFetchingGradeSlice= true;
    },
    [getSpecificStudentAssignmentGradesAdmin.fulfilled]: (state, { payload }) => {
      // console.log("Printing payload-getAllStudentCoursesByTerm", payload);
      state.isFetching = false;
      state.gradeData = payload.Data;
      state.studentName = payload.studentData
      return state;
    },
    [getSpecificStudentAssignmentGradesAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificStudentAssignmentGradesAdmin.pending]: (state) => {
      state.isFetching = true;
    },
    

  }
}
)


export const { clearState } = gradeSlice.actions;
export const gradeSliceFunction = (state) => state.grades;