import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'

const RejectionPopup = ({title,boldText,text,btnType,btnText,show,handleClose,btnHandler,onClick}) => {
  return (
    <div className={`popupOuter ${show?"active":""}`}>
    <div className="confirmationPopupContainer">
        <div className="popupHeader">
            <p className="popupTitle">{title}</p>
            <img src="../assets/icons/x-close.svg" alt="" onClick={handleClose}/>
        </div>
        <div className="popupBody">
            <p className="popupText"> <b>{boldText}</b>{' '}{text} </p>
            <div className="popupBtnGrp">
                <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'No'} onClick={handleClose} />
                <PrimaryButton classname={'dangerBtn'} children={'Yes'} onClick={onClick} />
            </div>
        </div>
    </div>
</div>
  )
}

export default RejectionPopup