import React, { useEffect, useState } from "react";
import "./DropdownStyle.css";

const Dropdown = ({
  label,
  options,
  type,
  isImportant,
  onChange,
  reset,
  setReset,
  isError,
  isDisabled,
}) => {
  const dropdownType = "dropdownListItem";
  const [active, setActive] = useState(false);
  // const [isError, setIsError] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select any Option");

  const resetValue = () => {
    setSelectedOption("Select any Option");
  };

  useEffect(() => {
    if (reset) {
      resetValue();
      setReset(false);
    }
    // eslint-disable-next-line
  }, [reset]);

  return (
    <div
      className={`dropdownBox ${active ? "active" : ""} ${
        isError ? "error" : ""
      } ${isDisabled ? "disabled" : ""}`}
      onBlur={() => {
        setActive(false);
      }}
      onClick={() => {
        setActive((prev) => !prev);
      }}
    >
      {label && (
        <label htmlFor="dropdown" className="formLabel">
          {label} {isImportant ? <span className="impMark">*</span> : null}
        </label>
      )}
      <div className="selectedOption" id="dropdown">
        {selectedOption}{" "}
        <img src="../assets/icons/chevron-down-black.svg" alt="" />
        {""}
        <ul className="dropdownList">
          {options?.map((option, index) => {
            return dropdownType === "checkbox" ? (
              <li
                className="dropdownListItem"
                key={index}
                onClick={() => {
                  setSelectedOption(option);
                }}
              >
                <div className="checkboxGrp">
                  <input type="checkbox" id={option} />
                  <label htmlFor={option}>{option}</label>
                </div>
              </li>
            ) : (
              <li
                className="dropdownListItem"
                key={index}
                onClick={() => {
                  setSelectedOption(option?.name);
                  onChange(option.id);
                }}
              >
                {option?.name}
              </li>
            );
          })}
        </ul>
      </div>
      {isError ? <p className="errorMsg">Error message</p> : ""}
    </div>
  );
};

export default Dropdown;