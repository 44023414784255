import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let studentId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  studentId = localStorage.getItem("studentId");
};

export const getStudentFeedbacks = createAsyncThunk(
  "feedback/getStudentFeedbacks",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      var search = obj?.searchTitle ?? '';
      var feedbackType = obj?.searchParams ?? '';
      var statusType = obj?.status ?? '';
      var pageSize = obj?.pageSize ?? ''; 
      var pageNo = obj?.pageNo ?? ''; 

      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        "/student/" +
        studentId +
        `/getStudentFeedbacks?searchTitle=${search}&searchParams=${feedbackType}&status=${statusType}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getParticularFeedbackQuestions = createAsyncThunk(
  "feedback/getParticularFeedbackQuestions",
  async (feedbackId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/org/" +
        orgId + "/" +
        feedbackId +
        "/getStudentFeedbackData",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const submitFeedbackAnswers = createAsyncThunk(
  "feedback/submitFeedbackAnswers",
  async ({ feedbackId, answerArray,feedbackType }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        orgId + "/student/" +
        studentId + "/" +
        feedbackId +
        "/createFeedbackAnswers",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify({ feedbackAnswers: answerArray,feedbackType:feedbackType})
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFeedbackStudentAnswer = createAsyncThunk(
  "feedback/getFeedbackStudentAnswer",
  async ( feedbackId , thunkAPI) => {
    try {
      console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/student/" +
          studentId +
          "/" +
          feedbackId +
          "/getStudentParticularFeedbackAnswer",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        console.log("inside if condition");
        return data.Data;
      } else {
        console.log("inside else condition");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    isFetching: false,
    quizSubmitted:false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    feedbackList: [],
    feedbackListCount: null,
    studentFeedbackData: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      state.feedbackList = [];
      state.studentFeedbackData = null;
      state.feedbackDetail = null;

      return state;
    },
    clearStateFeedback:(state) =>{
      state.isSuccess = false;
    }
  },
  extraReducers: {
    [getStudentFeedbacks.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.feedbackList = payload.Data;
      state.feedbackListCount = payload.Count;
      return state;
    },
    [getStudentFeedbacks.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getStudentFeedbacks.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getParticularFeedbackQuestions.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.studentFeedbackData = payload;
      return state;
    },
    [getParticularFeedbackQuestions.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getParticularFeedbackQuestions.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },
    [submitFeedbackAnswers.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.quizSubmitted = false;
      state.studentFeedbackData = payload;
      return state;
    },
    [submitFeedbackAnswers.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.quizSubmitted = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [submitFeedbackAnswers.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
      state.quizSubmitted = true;
    },
    [getFeedbackStudentAnswer.fulfilled]: (state, { payload }) => {
      console.log("printing payload deleting", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.studentAnswer = payload;
      // state.studentList = payload;
      return state;
    },
    [getFeedbackStudentAnswer.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
      // state.feedbackList = payload;
    },
    [getFeedbackStudentAnswer.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
  }
});

export const { clearState,clearStateFeedback } = feedbackSlice.actions;

export const feedbackData = (state) => state.feedback;
