import React from 'react'
import './TextAreaStyle.css'

const TextArea = ({ labelName, placeholder, isImportant, value, onChange }) => {
  return (
    <div className="formGroup dFlex gap5 flexColumn">
      <label htmlFor="Email">
        {labelName}
        {isImportant ? <span className='impMark'>*</span> : null}
      </label>
      <textarea className='formInput' rows={5} placeholder={placeholder} 
                value={value}
                onChange={onChange}
                 />
    </div>
  )
}

export default TextArea