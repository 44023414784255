import React from 'react'
import './TextFieldStyle.css';

const TextField = ({labelName,inputType,placeholder,isImportant,disabled,value,onChange}) => {
    return (
        <div className="formGroup dFlex gap5 flexColumn">
            <label htmlFor="Email">
                {labelName}
                {isImportant? <span className='impMark'>*</span>:null }
            </label>
            <input
                className="formInput"
                type={inputType}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default TextField