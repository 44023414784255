import React, { useEffect, useState } from "react";
import "./SubmitFeedback.css";
import Button from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
// import Buttons from '../../../components/globalComponents/buttons/Buttons'
import BreadCrumbs from "../../../components/globalComponents/breadCrumbs/BreadCrumbs";
import Paragraph from "../../../components/feedbackComponents/Paragraph/Paragraph";
import Rating from "../../../components/feedbackComponents/rating/Rating";
import SingleSelect from "../../../components/feedbackComponents/singleSelect/SingleSelect";
import MultiSelect from "../../../components/feedbackComponents/multiSelect/MultiSelect";
import UploadBox from "../../../components/feedbackComponents/UploadBox/UploadBox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearState,
  feedbackData,
  getParticularFeedbackQuestions,
  submitFeedbackAnswers,
} from "../../../reducers/feedbackSlice";
import PageLoader from "../../../components/globalComponents/PageLoader/PageLoader";
import moment from "moment";
import ErrorToast from "../../../components/Toast/ErrorToast";

const SubmitFeedback = () => {
  // const layers = ["List of feedback", "Product Management | Session"];

  // const onSelect = (array) => {

  // }

  // const selected = (arr) => {

  // }

  const dispatch = useDispatch();
  // const [currentIndex, setCurrentIndex] = useState(-1);
  const param = useParams();
  const feedbackId = param.id;
  const [isLoading, setIsLoading] = useState(false);
  const [mandatoryFeedbacks, setMandatoryFeedbacks] = useState([]);
  const [previewType, setPreviewType] = useState("");
  const [sliderArray, setQuestionArray] = useState([]);
  const [layoutType, setLayoutType] = useState("");
  const [feedbackInfo, setFeedbackInfo] = useState({});
  const [answerArray, setAnswerArray] = useState([]);

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const navigate = useNavigate();

  console.log(layoutType,"layoutType",feedbackInfo)

  const { isFetching, isSuccess, isError, studentFeedbackData } =
    useSelector(feedbackData);

  useEffect(() => {
    return () => {
      console.log("cleared");
      dispatch(clearState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    try {
      if (
        feedbackId !== null ||
        feedbackId !== undefined ||
        feedbackId !== "null"
      ) {
        dispatch(getParticularFeedbackQuestions(feedbackId));
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line
  }, [feedbackId]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      // history.push('/');
      navigate("/feedbackList", {replace: true});
    }
    if (isError) {
      // toast.error(errorMessage);
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isSuccess, isError]);

  useEffect(() => {
    setIsLoading(isFetching);
    // eslint-disable-next-line
  }, [isFetching]);

  useEffect(() => {
    if (studentFeedbackData === null || studentFeedbackData === undefined) {
      return;
    } else {
      console.log("studentFeedbackData: ", studentFeedbackData);
      let { FeedbackQuestions, ...feedData } = studentFeedbackData;

      setPreviewType(feedData && feedData.previewType);
      setLayoutType(feedData && feedData.layoutType);
      setFeedbackInfo(feedData);
      var array = [];
      setMandatoryFeedbacks([]);
      FeedbackQuestions !== undefined &&
        FeedbackQuestions.forEach((element, i) => {
          if (!element.isNotMandatory) {
            setMandatoryFeedbacks((prev) => [...prev, element.id]);
          }
          var item = {
            id: element.id,
            questionType: element.questionType,
            questionName: element.questionName,
            isNotMandatory: element.isNotMandatory,
            option: element.FeedbackQuestionOptions,
          };

          array.push(item);
        });

      setQuestionArray(array);
    }
    // eslint-disable-next-line
  }, [studentFeedbackData]);

  // const previousState = () => {
  //   if (currentIndex === -1) {
  //     return 0;
  //   }
  //   setCurrentIndex(currentIndex - 1);
  // };

  // const nextState = () => {
  //   if (currentIndex !== sliderArray.length) {
  //     if (currentIndex === sliderArray.length - 1) {
  //       return 0;
  //     }
  //     // this.setState({ currentIndex: currentIndex + 1 });
  //     setCurrentIndex(currentIndex + 1);
  //     console.log("inside if ", currentIndex);
  //   }
  //   // if(currentIndex === sliderArray.data.length)
  // };
  const setAnswerObj = (answer, quesObj) => {
    let answerObj;
    let isDelete = false;
    if (
      quesObj.questionType === "Single Select" ||
      quesObj.questionType === "Multi Select"
    ) {
      if (answer.length === 0) {
        isDelete = true;
      }

      answerObj = {
        feedbackId: feedbackId,
        feedbackQuestionId: quesObj.id,
        feedbackOptionId: answer,
        answer: "",
      };
    } else {
      if (answer === "") {
        isDelete = true;
      }
      answerObj = {
        feedbackId: feedbackId,
        feedbackQuestionId: quesObj.id,
        feedbackOptionId: quesObj.option.map((op) => op.id),
        answer: answer.toString(),
      };
    }
    let idx = answerArray.findIndex(
      (obj) => obj.feedbackQuestionId === answerObj.feedbackQuestionId
    );
    if (idx === -1) {
      setAnswerArray((prev) => [...prev, answerObj]);
    } else {
      let arr = answerArray;
      if (isDelete) {
        arr.splice(idx, 1);
      } else {
        arr[idx] = answerObj;
      }
      setAnswerArray(arr);
    }
  };

  // const setSliderAnswer = (currentIndex) => {
  //     if (currentIndex === -1) {
  //         return;
  //     }
  //     console.log("answerArray: ", answerArray);
  //     console.log("sliderArray questionName: ", sliderArray[currentIndex].questionName);
  //     console.log("curr Que Id :", sliderArray[currentIndex].id);

  //     let ans = answerArray.find(x => x.feedbackQuestionId === sliderArray[currentIndex].id)
  //     console.log("answer ans: ", ans);
  //     if (ans === undefined) {
  //         setSliderAns('');
  //     } else {
  //         setSliderAns(ans);
  //     }
  // }
  console.log(answerArray);

  const submitFeedback = () => {
    console.log("Inside feedback submit", answerArray);
    let attemptedQuestions = answerArray.map((val) => val.feedbackQuestionId);
    let NotFilled = mandatoryFeedbacks.every((id) =>
      attemptedQuestions.includes(id)
    );
    if (answerArray.length === 0 || !NotFilled) {
      setIsShowError(true);
      setErrMessage("All questions are mandatory !")
      let unAttemptedId = mandatoryFeedbacks.filter(
        (val) => !attemptedQuestions.includes(val)
      );
      console.log("unAttemptedId: ", unAttemptedId);
      if (previewType === "singlePage") {
        console.log("scroll to ", unAttemptedId[0]);
      }
      return;
    }
    console.log(answerArray, "letts go");

    dispatch(submitFeedbackAnswers({ feedbackId, answerArray }));
  };

  return (
    <>
      {isLoading && <PageLoader />}
      {/* <BreadCrumbs layers={layers} /> */}
      
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="pageHeader">
      <div
        onClick={() => navigate(-1)}
        style={{ padding:"8px 0px" }}
        className="backBtn"
        >
        <img src="/assets/icons/back-btn.svg" alt="" />
        Back to Page
      </div>
      <div style={{display:"flex",alignItems:"center",marginRight:"100px"}}>
        <p style={{fontWeight:"bold"}}>Submit Feedback</p>
      </div>
      <div></div>
      </div>
      {studentFeedbackData && (
        <div className="main-wrapper with-card feedback-wrapper">
          <div className="fullScreenWrapper">
            <div className="question-box">
              <h1 className="heading-2">
                {studentFeedbackData.title} -<br />
                {moment(studentFeedbackData.publishedOn).format(
                  "Do MMM YYYY"
                )}{" "}
                -
                <br />
                {studentFeedbackData?.Admin?.firstName}{" "}
                {studentFeedbackData?.Admin?.lastName}
              </h1>
              <p className="text-3 mt-20">{studentFeedbackData.description}</p>
              <Button
                children={"Get Started"}
                classname={"dangerBtn mt-40"}
                isdisabled={false}
              />
            </div>
          </div>

          {sliderArray?.map((quest, i) => (
            <div className="fullScreenWrapper">
              <div className="question-box" key={quest.id} id={quest.id}>
                <h1 className="text-3">{quest.questionName ?? quest.title}</h1>
                {/* <Rating item={{ id: "aj" }} /> */}
                {quest.questionType === "Paragraph" && (
                  <Paragraph
                    item={quest}
                    onChange={(e) => setAnswerObj(e, quest)}
                  />
                )}
                {quest.questionType === "Rating" && (
                  <Rating
                    item={quest}
                    onSelect={(e) => setAnswerObj(e, quest)}
                  />
                )}
                {quest.questionType === "Single Select" && (
                  <SingleSelect
                    item={quest}
                    onSelect={(e) => setAnswerObj(e, quest)}
                  />
                )}
                {quest.questionType === "Multi Select" && (
                  <MultiSelect
                    item={quest}
                    onSelect={(e) => setAnswerObj(e, quest)}
                  />
                )}
                {quest.questionType === "Upload Media" && (
                  <UploadBox
                    item={quest}
                    onLoading={setIsLoading}
                    onUpload={(e) => setAnswerObj(e, quest)}
                  />
                )}
                {/* <Button onClick={handleClick} children={'Continue'} classname={'feedbackDangerBtn mt-40'} isdisabled={false} /> */}
              </div>
            </div>
          ))}
          <Button
            onClick={submitFeedback}
            children={"Submit"}
            classname={"feedbackDangerBtn mt-40"}
            isdisabled={false}
          />
        </div>
      )}
    </>
  );
};

export default SubmitFeedback;
