import React, { useEffect, useState } from "react";
import "./feedBackStyle.css";
// import Button from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
// import Buttons from '../../../components/globalComponents/buttons/Buttons'
import BreadCrumbs from "../../../components/globalComponents/breadCrumbs/BreadCrumbs";
// import CreateInput from '../../../components/feedbackComponents/createInput/CreateInput';
// import Rating from '../../../components/feedbackComponents/rating/Rating';
// import SingleSelect from '../../../components/feedbackComponents/singleSelect/SingleSelect';
// import MultiSelect from '../../../components/feedbackComponents/multiSelect/MultiSelect';
// import UploadBox from '../../../components/feedbackComponents/UploadBox/UploadBox';
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  feedbackData,
  getFeedbackStudentAnswer,
  getParticularFeedbackQuestions,
} from "../../../reducers/feedbackSlice";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../../components/globalComponents/PageLoader/PageLoader";

const FeedBackPreview = () => {
  // const layers = ["List of feedback", "Product Management | Session"];
  const navigate = useNavigate();

  const param = useParams();
  const feedId = param.id;
  const [questionArray, setQuestionArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [answersArray, setAnswersArray] = useState([]);
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  const [feedbackType, setFeedbackType] = useState("");

  const { isFetching, isError, studentFeedbackData, studentAnswer } =
    useSelector(feedbackData);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    setIsLoading(isFetching);
    // eslint-disable-next-line
  }, [isFetching]);

  useEffect(() => {
    try {
      if (feedId === null || feedId === undefined || feedId === "null") {
        return;
      }
      dispatch(getParticularFeedbackQuestions(feedId));
      dispatch(getFeedbackStudentAnswer(feedId));
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line
  }, [feedId]);

  useEffect(() => {
    console.log("getting feedback details", studentFeedbackData);
    if (
      studentFeedbackData === null ||
      studentFeedbackData === undefined ||
      studentFeedbackData === "null"
    ) {
      return;
    }
    // setPreviewType(studentFeedbackData && studentFeedbackData.previewType);
    // setLayoutType(studentFeedbackData && studentFeedbackData.layoutType);

    var array = [];

    studentFeedbackData &&
      studentFeedbackData.FeedbackQuestions?.forEach((element, index) => {
        var item = {
          id: element.id,
          questionType: element.questionType,
          questionName: element.questionName,
          isMandatory: element.isMandatory,
          option: element.FeedbackQuestionOptions,
        };

        array.push(item);
      });

    console.log("fdfddffd", array);
    setQuestionArray(array);
    if (studentFeedbackData.feedbackType === "Quiz") setFeedbackType("Quiz");
    else setFeedbackType("");
    // setAnswersArray(studentAnswer);
    // eslint-disable-next-line
  }, [studentFeedbackData]);

  useEffect(() => {
    console.log("studentAnswer:  ", studentAnswer);
    if (studentAnswer === undefined || studentAnswer === null) {
      return;
    }
    const res = Object?.values(
      studentAnswer?.reduce(
        (a, { feedbackQuestionId, feedbackOptionId, answer }) => {
          a[feedbackQuestionId] = a[feedbackQuestionId] || {
            feedbackQuestionId,
            feedbackOptionId: new Set(),
            answer,
          };
          a[feedbackQuestionId].feedbackOptionId.add(feedbackOptionId);
          return a;
        },
        {}
      )
    ).map(({ feedbackQuestionId, feedbackOptionId, answer }) => ({
      feedbackQuestionId,
      feedbackOptionId: [...feedbackOptionId],
      answer: answer,
    }));

    console.log(res);
    setFilteredAnswers(res);
    // eslint-disable-next-line
  }, [studentAnswer]);

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="pageHeaderType3">
        <div
          onClick={() => navigate(-1)}
          style={{ padding: "8px 0px" }}
          className="backBtn"
        >
          <img src="/assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        <div style={{ marginRight: "100px" }}>
          <b>Preview</b>
        </div>
        <div></div>
      </div>
      {/* <div>Feedback preview</div> */}

      <div className="main-wrapper with-card feedback-wrapper">
        {questionArray.map((item, i) => {
          const {
            id,
            questionType,
            questionName,
            type,
            // answers,
            // title,
            // desc,
            // required,
          } = item;

          let questionAnswer = filteredAnswers.filter((answer) => {
            return answer.feedbackQuestionId === id;
          });

          questionAnswer = questionAnswer[0];

          return (
            <React.Fragment key={id}>
              {questionType === "Paragraph" && (
                <div className="fullScreenWrapper">
                  <div className="question-box">
                    <h2 className="heading-2">{questionName}</h2>
                    <p className="feedbacka-answer">{questionAnswer?.answer}</p>
                  </div>
                </div>
              )}

              {questionType === "Single Select" && (
                <div className="fullScreenWrapper">
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="singleselect mt-30 col-3">
                            {console.log(type, "type")}
                            {item.option.map((listItem) => (
                              <label
                                style={
                                  feedbackType === ""
                                    ? questionAnswer?.feedbackOptionId.includes(
                                        listItem.id
                                      )
                                      ? { background: "#FCE794" }
                                      : null
                                    : null
                                }
                                className={`singleselectlabel ${
                                  feedbackType !== ""
                                    ? questionAnswer?.feedbackOptionId.includes(
                                        listItem.id
                                      ) && listItem.isCorrect === false
                                      ? "danger"
                                      : ""
                                    : ""
                                }
                                ${
                                  feedbackType !== ""
                                    ? listItem.isCorrect === true
                                      ? "feedSuccess"
                                      : ""
                                    : ""
                                }
                                `}
                                key={listItem.id}
                              >
                                <input
                                  className="singleselect-btn"
                                  disabled
                                  onChange={() => {}}
                                  checked={questionAnswer?.feedbackOptionId.includes(
                                    listItem.id
                                  )}
                                  type="radio"
                                />
                                <span className="singleselectradio" />
                                {listItem.value}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {questionType === "Multi Select" && (
                <div className="fullScreenWrapper">
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="mt-30 checkmark-wrapper col-3">
                            {item.option.map((listItem) => (
                              <label
                                className={`checkmark-label ${
                                  questionAnswer?.feedbackOptionId.includes(
                                    listItem.id
                                  )
                                    ? "active"
                                    : ""
                                }`}
                                key={listItem.id}
                              >
                                <input
                                  type="checkbox"
                                  disabled
                                  onChange={() => {}}
                                  className="checkmark-btn"
                                  checked={questionAnswer?.feedbackOptionId.includes(
                                    listItem.id
                                  )}
                                />
                                <span className="checkmarkspan"></span>
                                    <p className="checkmarkText">  {listItem.value}</p>
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {questionType === "Upload Media" && (
                <div className="fullScreenWrapper">
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="mt-30 checkmark-wrapper col-3">
                            {/* <img src={questionAnswer?.answer}  width="auto" height="auto"></img> */}
                            <a href={questionAnswer?.answer} target="_blank">
                              Click to Preview
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {questionType === "Rating" && (
                <div className="fullScreenWrapper">
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>

                          <div className="rating-wrapper mt-30">
                            <div className="rating">
                              <input
                                checked={questionAnswer?.answer === "5"}
                                onChange={() => {}}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}5`}
                              />
                              <label htmlFor="rating-5"></label>
                              <input
                                checked={questionAnswer?.answer === "4"}
                                onChange={() => {}}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}4`}
                              />
                              <label htmlFor="rating-4"></label>
                              <input
                                checked={questionAnswer?.answer === "3"}
                                onChange={() => {}}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}3`}
                              />
                              <label htmlFor="rating-3"></label>
                              <input
                                checked={questionAnswer?.answer === "2"}
                                onChange={() => {}}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}2`}
                              />
                              <label htmlFor="rating-2"></label>
                              <input
                                checked={questionAnswer?.answer === "1"}
                                onChange={() => {}}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}1`}
                              />
                              <label htmlFor="rating-1"></label>
                            </div>
                            <div className="rating-count">
                              {questionAnswer?.answer} out of 5
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          );

          //             return answersArray.map((element) => {
          //               return (
          //                 <div className="question-box">
          //                   <div className="inside-shell">
          //                     <div className="inside-mid card-style">
          //                       <div className="inside-content">
          //                         {questionType === "paragraph" &&
          //                           element.feedbackQuestionId === id && (
          //                             <div>
          //                               <h2 className="heading-2">{questionName}</h2>
          //                               <span>{element.answer}</span>
          //                             </div>
          //                           )}

          //                         {(questionType === "singleSelect" &&
          //                           element.feedbackQuestionId === id) && (
          //                             <div>
          //                               <h2 className="heading-2">{questionName}</h2>

          //                               {item.option.map((listItem) => (
          //                                 <div className="singleselect mt-30 col-3">
          //                                   <label className="singleselectlabel">
          //                                     <input
          //                                       className="singleselect-btn"
          //                                       disabled
          //                                       defaultChecked={
          //                                         listItem.id === element.feedbackOptionId
          //                                       }
          //                                       type="radio"
          //                                     />
          //                                     <span className="singleselectradio" />
          //                                     {listItem.value}
          //                                   </label>
          //                                 </div>
          //                               ))}
          //                             </div>
          //                           )}

          //                         {questionType === "multiSelect" &&
          //                           element.feedbackQuestionId === id && (
          //                             <div>
          //                               <h2 className="heading-2">{questionName}</h2>

          //                               {item.option.map((listItem) => (
          //                                 <div className="mt-30 checkmark-wrapper col-3">
          //                                   <label className="checkmark-label">
          //                                     <input
          //                                       type="checkbox"
          //                                       disabled
          //                                       className="checkmark-btn"
          //                                       defaultChecked={
          //                                         listItem.id === element.feedbackOptionId
          //                                       }
          //                                     />
          //                                     <span className="checkmarkspan"></span>
          //                                     {listItem.value}
          //                                   </label>
          //                                 </div>
          //                               ))}
          //                             </div>
          //                           )}

          //                         {questionType === "rating" &&
          //                           element.feedbackQuestionId === id && (
          //                             <div>
          //                               <h2 className="heading-2">{questionName}</h2>

          //                             </div>
          //                           )}

          //                         {questionType === "uploadMedia" &&
          //                           element.feedbackQuestionId === id && (
          //                             <div>
          //                               <h2 className="heading-2">{questionName}</h2>
          //                               <div className="uploadbox_wrapper mt-30">
          //                                 <div className="upload_shell active">
          //                                   <div className="upload_inner">
          //                                     <label
          //                                       htmlFor="uploadbox"
          //                                       className="active"
          //                                     >
          //                                       {element.answer}
          //                                     </label>
          //                                   </div>
          //                                 </div>
          //                               </div>
          //                             </div>
          //                           )}

          //                         {/* {element.f

          //                             eedbackQuestionId === id && (
          //                               <textarea
          //                                 className="create-input"
          //                                 name=""
          //                                 id=""
          //                                 cols="5"
          //                                 rows="5"
          //                                 // defaultValue={element.answers}
          //                                 placeholder={"element.answers"}
          //                               />
          //                             )} */}

          //                         {/* paragraph */}
          //                         {/* rating bar */}
          //                         {/* <div className="rating-wrapper mt-30">
          // <div className="rating">
          // <input type="radio" name="rating" id="rating-5" />
          // <label htmlFor="rating-5"></label>
          // <input type="radio" name="rating" id="rating-4" />
          // <label htmlFor="rating-4"></label>
          // <input type="radio" name="rating" id="rating-3" />
          // <label htmlFor="rating-3"></label>
          // <input type="radio" name="rating" id="rating-2" />
          // <label htmlFor="rating-2"></label>
          // <input type="radio" name="rating" id="rating-1" />
          // <label htmlFor="rating-1"></label>
          // </div>
          // <div className="rating-count">4 out of 5</div>
          // </div> */}
          //                         {/* rating bar */}
          //                         {/* multi-select */}
          //                         {/* <div className="mt-30 checkmark-wrapper col-3">
          // <label className="checkmark-label">
          // <input type="checkbox" className="checkmark-btn" />
          // <span className="checkmarkspan"></span>
          // Item Value
          // </label>
          // </div> */}
          //                         {/* multi-select */}
          //                         {/* single-select */}
          //                         {/* <div className="singleselect mt-30 col-3">
          // <label className="singleselectlabel">
          // <input className="singleselect-btn" type="radio" />
          // <span className="singleselectradio" />
          // item value
          // </label>
          // </div> */}
          //                         {/* single-select */}
          //                         {/* upload-media */}
          //                         {/* <div className="uploadbox_wrapper mt-30">
          // <div className="upload_shell active">
          // <div className="upload_inner">
          // <label htmlFor="uploadbox" className="active">
          // file-name
          // </label>
          // </div>
          // </div>
          // </div> */}
          //                         {/* upload-media */}
          //                       </div>
          //                     </div>
          //                   </div>
          //                 </div>
          //               );
          //             });
        })}
      </div>
      {/* <div className='main-wrapper with-card feedback-wrapper'>

                <div className="question-box">
                <h1 className="heading-2">Managerial Strategy - Session 02 - 12th Jan 220 -<br/>Mr. Thomas Kuruvilla</h1>
                <p className="text-3 mt-20">The PGP TBM is an extremely flexible programme that can be highly customized to <br/>meet the needs of each student’s career aspirations.</p>
                <Button onclick={log} children={'Get Started'} classname={'feedbackDangerBtn mt-40'} isdisabled={false}/>
            </div>

                <div className="question-box">
                <CreateInput />
                <Button onclick={log} children={'Get Started'} classname={'feedbackDangerBtn mt-40'} isdisabled={false}/>
            </div>

                <div className="question-box">
                <h1 className="text-3">Please give us rating accordingly</h1>
                <Rating item={{id:"aj"}}/>
                <Button onclick={log} children={'Get Started'} classname={'feedbackDangerBtn mt-40'} isdisabled={false}/>
            </div>

                <div className="question-box">
                <h1 className="text-3">Please select many option from below options</h1>
               <SingleSelect item={{option:["test"]}} value={{feedbackOptionId:[{id:"1"}]}} onSelect={onSelect}/>
                <Button onclick={log} children={'Get Started'} classname={'feedbackDangerBtn mt-40'} isdisabled={false}/>
            </div>

                <div className="question-box">
                    <h1 className="text-3">Please give us rating accordingly</h1>
                    <MultiSelect item={{ option: [{ id: "test" }] }} value={{ feedbackOptionId: [{ id: "1" }] }} select={selected} />
                    <Button onclick={log} children={'Get Started'} classname={'dangerBtn mt-40'} isdisabled={false} />
                </div>

                <div className="question-box">
                    <h1 className="text-3">Please give us rating accordingly</h1>
                    <UploadBox value={{ answer: "aj" }} item={{ id: "" }} />
                    <Button onclick={log} children={'Get Started'} classname={'feedbackDangerBtn mt-40'} isdisabled={false} />
                </div>

            </div> */}
    </>
  );
};

export default FeedBackPreview;
