import React, { useEffect, useState } from 'react';
import "./Paragraph.css";

const Paragraph = (props) => {
        const [val, setVal] = useState('');
        const handleChange = (e) => {
            setVal(e.target.value);
            props.onChange(e.target.value);
        }

        useEffect(() => {
            console.log("in para ans ",props.value);
            if(props.value && props.value !== ''){
                setVal(props?.value?.answer ?? '');
            }
        }, [props?.value]);

        return (<textarea style={{padding:"16px"}} onChange = {handleChange} value = {val} className='create-input mt-30' name="" id={props.item.id} cols="5" rows="5" placeholder='type your answer'></textarea>
        
    )
}

export default Paragraph;