import React, { useEffect, useState } from "react";
import "./MultiSelectStyle.css";

const MultiSelect = (props) => {
    const [checked, setChecked] = useState([]);
    // Add/Remove checked item from list
    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, { id: event.target.id, value: event.target.value }];
        } else {
            updatedList.splice(checked.map(function (e) { return e.id; }).indexOf(event.target.id), 1);
        }
        setChecked(updatedList);
        props.onSelect(updatedList);
    };

    useEffect(() => {
        if (props.value) {
            setChecked(props.value?.feedbackOptionId);
        }
    }, [props?.value]);

    return (
        <div className="mt-30 checkmark-wrapper col-3">
            {props.item.option.map((item, index) => (
                <label
                    className={
                        checked.some((ch) => ch.id === item.id)
                            ? "checkmark-label active"
                            : "checkmark-label"
                    }
                    key={index}
                >
                    <input
                        value={item.value}
                        type="checkbox"
                        checked={checked.some((ch) => ch.id === item.id)}
                        className="checkmark-btn"
                        onChange={handleCheck}
                        id={item.id}
                    />
                    <span className="checkmarkspan"></span>
                    <p className="checkmarkText">{item.value}</p>
                </label>
            ))}
        </div>
    );
};

export default MultiSelect;
