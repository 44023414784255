import React, { useEffect, useState } from "react";
import "./LeaveDetailsStyle.css";
import BreadCrumbs from "../../../components/globalComponents/breadCrumbs/BreadCrumbs";
import FileViewer from "../../../components/globalComponents/fileViewer/FileViewer";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getMissingData, getParticularLeaveDetails, userSliceFunction } from "../../../reducers/leaveSlice";
import { useSearchParams } from "react-router-dom";
import moment from 'moment';
import PageLoader from "../../../components/globalComponents/PageLoader/PageLoader";
const LeaveDetails = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("leaveId");
  const { isFetching, getData, missingLactures } = useSelector(userSliceFunction);
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getParticularLeaveDetails(id));
  }, [id]);

  useEffect(() => {
    setEndDate(getData.toDate);
    if (endDate !== '') {
      dispatch(getMissingData({ startTime: moment(getData?.fromDate).format('YYYY-MM-DD'), endTime: moment(getData?.toDate).format('YYYY-MM-DD') }));
    }
  }, [getData]);

  return (
    <>
      {isFetching && getData.fromDate !== '' && <PageLoader />}
      <div className="pageHeader">
      <div className="backBtn" onClick={() => navigate(-1)}>
          <img src="/assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="leaveDetailsContainerOuter">
        <div className="leaveDetailsContainer">
          <div className="leaveDetailsHeader">
            <p className="heading">Student name: {getData?.Student?.firstName}</p>
            <div>
              {getData?.leaveStatus === "accepted" && <div className="successTag">Approved</div> || getData?.leaveStatus === "rejected" && <div className="dangerTag">Rejected</div> || getData?.leaveStatus === "cancelled" && <div className="dangerTag">Cancelled</div> || <div className="greyTag">Pending</div>}
            </div>
          </div>
          <div className="leaveDetailsTable">
            <div className="leaveDetailsTableElement">
              <p className="heading">Leave Type</p>
              {getData?.leaveType}
            </div>
            <div className="leaveDetailsTableElement">
              <p className="heading">Leave From</p>
              {moment(getData?.fromDate).format("DD-MM-YYYY")}
            </div>
            <div className="leaveDetailsTableElement">
              <p className="heading">Leave To</p>
              {moment(getData?.toDate).format("DD-MM-YYYY")}
            </div>
          </div>
          <p className="descriptionHeading">Description</p>
          <p className="leaveDetailsTableElement">
            {getData?.Reason}
          </p>
         <p style={getData?.leaveType === 'Casual Leave' ? {display:'none'} : null} className="supportingDocHeading">Supporting Documents</p>
          <div style={getData?.leaveType === 'Casual Leave' ? {display:'none'} : null} className="supportingDocumentContainer">
         {getData?.uploadDocument ? <FileViewer fileUrl={getData?.uploadDocument} fileName={getData?.fileName===null?"Document":getData?.fileName}/>:<>No uploaded documents</>}
          </div>
          <div className="missingTableContainer">
            <table
              className="missingLectureTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th className="missingTd"><p>Missing Session</p> <p>Total Missed : {missingLactures?.length}</p></th>
                </tr>
              </thead>
              <tbody>
                {missingLactures?.map(item => {
                  return (
                    <tr>
                      <td>{item?.name}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveDetails;
