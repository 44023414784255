import React, { useState, useEffect, useRef } from "react";
// import Dropdown from "../../components/globalComponents/dropdown/Dropdown";
import ProgressBar from "../../components/globalComponents/progressBar/ProgressBar";
import FileViewer from "../../components/globalComponents/fileViewer/FileViewer";
import Button from "../../components/globalComponents/buttons/primaryButton/PrimaryButton";
import QuizPopup from "../../components/popups/quizPopup/QuizPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { lmsSliceData, getCourseById, clearState2 ,getCourseByStudentId} from "../../reducers/lmsSlice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/globalComponents/PageLoader/PageLoader";
import {
  gradeSliceFunction,
  getSpecificStudentAssignmentGrades,
  submitStudentAssignment,
  clearState,
  getAllQuiz,
} from "../../reducers/gradeSlice";
import {
  submitFeedbackAnswers,
  feedbackData,clearStateFeedback
} from "../../reducers/feedbackSlice";

import GeneralQuizPopup from "../../components/popups/generalQuizPopup/GeneralQuizPopup";
import moment from "moment";

const CourseAssignment = () => {
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const { isFetching, singleCourseDetail, coursesFetched,getStudentCourseData } = useSelector(lmsSliceData);

  const { assignmentData, isFetchingGradeSlice, isSuccess2, quizData } =
    useSelector(gradeSliceFunction);

  const { isSuccess,quizSubmitted } = useSelector(feedbackData);
  const navigate = useNavigate();

  const [toggleCourse, setToggleCourse] = useState(0);
  const [toggleCourse2, setToggleCourse2] = useState(0);
  const [searchParams] = useSearchParams();
//   const courseId = searchParams.get("courseId") ?? "";
  // const [asgnClick, setAsgnClick] = useState('')
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const [courseId, setCourseId] = useState("false");
  const [data, setData] = useState("");
  const [active, setActive] = useState(false);
  const [ansArr, setAnsArr] = useState([]);
  const [index, setIndex] = useState("");
  const increment = useRef(null);
  const dec = useRef(null);
  const [viewRemarks,setViewRemarks]=useState(false)

  const [assignData, setAssignData] = useState([]);
  useEffect(() => {
    dispatch(getCourseByStudentId());
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(getStudentCourseData?.length >0 && coursesFetched){
        let obj = {
            courseId: getStudentCourseData[0]?.CourseRoster?.id,
            sessionId: "All",
            type:"course"
          };
        setCourseId(getStudentCourseData[0]?.CourseRoster?.id);
        dispatch(clearState2())
        dispatch(getSpecificStudentAssignmentGrades(obj));
        console.log("Okk");
    }
  },[getStudentCourseData,coursesFetched])
  console.log(coursesFetched,"coursesFetched");


  useEffect(() => {
    if (courseId && active ) {
        let obj = {
            courseId: courseId,
            sessionId: "All",
            type:"course"
          };
        dispatch(getSpecificStudentAssignmentGrades(obj));
      dispatch(clearState2());
      setActive(false);
    }
    // eslint-disable-next-line
  }, [active,courseId]);

  useEffect(() => {
    let obj = {
      courseId: courseId,
      sessionId: "All",
      type:"course"
    };
    if (isSuccess) {
      setShow(!show)
      
      dispatch(clearStateFeedback());

    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (assignmentData?.length > 0) {
      const arr = [assignmentData[0]];
      setAssignData(arr);
    }
  }, [assignmentData]);
  // console.log(assignData,"1111222266666")

  const getPercentage = () => {
    let newData = singleCourseDetail?.CourseSessions;
    let allSessData =
      singleCourseDetail &&
      singleCourseDetail?.CourseSessions?.filter(
        (data) => data?.isCompleted === true
      );
    // console.log(allSessData, "singleCourseDetail");

    return newData?.length > 0
      ? Math.round(
        (allSessData?.length / singleCourseDetail?.CourseSessions?.length) *
        100
      )
      : 0;
  };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const handleSubmit = (id) => {
    const data = assignmentData.filter((i) => i.id === id);

    setAssignData(data);
    setView(true);
    setSubmit(false);
  };

  const handleView = (id) => {
    const data = assignmentData.filter((i) => i.id === id);
    setAssignData(data);
    setView(true);
    setSubmit(true);
    setViewRemarks(false)
  };
  const handleViewRemarks = (id) => {
    const data = assignmentData.filter((i) => i.id === id);
    setAssignData(data);
    setView(true);
    setSubmit(true);
    setViewRemarks(true)
  };
  
  // useEffect(() => {
  //   console.log(ansArr, "Question ID")
  // }, [ansArr])


  const functionCall = (e) => {
    dispatch(submitStudentAssignment(e));
  };

  useEffect(() => {
    if (isSuccess2) {
      setView(false);
      let obj = {
        courseId: courseId,
        sessionId: "All",
        type:"course"
      };
      dispatch(getSpecificStudentAssignmentGrades(obj));
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isSuccess2]);

  return (
    <>
      {(isFetching || isFetchingGradeSlice ||quizSubmitted ) && <PageLoader />}
      <GeneralQuizPopup
        value={view}
        onChange={setView}
        asgnId={assignData}
        func={functionCall}
        submit={submit}
        setSubmit={setSubmit}
        viewRemarks={viewRemarks}
      />
      <div className="courseDetailsOuter">
        <div className="courseDetailsMenuOuter">
          {/* <div className="sessionDetailsOuter">
            <p className="sessionName">{singleCourseDetail?.courseName}</p>
            <div className="sessionDetials">
              <p>Session {singleCourseDetail?.CourseSessions?.length}</p>
              <p>{getPercentage()}%</p>
            </div>
            <ProgressBar
              width={`${getPercentage()}%`}
              color={"var(--success-4)"}
            />
          </div> */}
          <h4 className="courseDetailsMenuHeading">Course Overview</h4>

          {getStudentCourseData?.length > 0 &&
            getStudentCourseData?.map((res, i) => (
              <div
                className={`sessionOverviewCard ${toggleCourse === i ? "active" : ""
                  }`}
                onClick={() => { if(res?.CourseRoster?.id!==courseId){setToggleCourse(i); setActive(true);setCourseId(res?.CourseRoster?.id)} }}
              >
                <div className="sessionCardHeader">
                  <p className="sessionHeading">{res?.CourseRoster?.courseName}</p>
                  <img src="../assets/icons/arrow-narrow-right.svg" alt="" />
                </div>
                <div className="currentStatus">
                  {res.isCompleted ? (
                    <img src="../assets/icons/check-circle.svg" alt="" />
                  ) : (
                    <img src="../assets/icons/pendingCircle.svg" alt="" />
                  )}

                  {res.isCompleted ? (
                    <p className="successText">Completed</p>
                  ) : (
                    <p className="secondaryText">Pending</p>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="courseContentOuter">
          <h2 className="heading">Course Assignment</h2>

          {assignmentData?.map((num, index) => (
            <div
              className={`courseContentBox ${toggleCourse2 === index ? "active" : ""
                }`}
              onClick={() => setToggleCourse2(index)}
              key={index}
            >
              <div className="courseContentBoxHeader">
                <div className="tags">
                  <div className="primaryTag">General</div>
                </div>
                <div className="toggleBtn" onClick={() => setToggleCourse2(3)}>
                  {toggleCourse2 === 3 ? <p>Collapse</p> : <p>Expand</p>}
                  <img
                    src="../assets/icons/chevron-down-black.svg"
                    alt=""
                    className="toggleBtnIcon"
                  />
                </div>
              </div>
              <div className="courseContentBodyHeader">
                <p className="sessionName">{num?.Assignment?.name}.</p>
                <div className="currentStatus">
                  {num.assignmentStatus !== "Submitted" ? (
                    <img src="../assets/icons/pendingCircle.svg" alt="" />
                  ) : (
                    <img src="../assets/icons/check-circle.svg" alt="" />
                  )}
                  <p className="secondaryText">{num?.assignmentStatus}</p>
                </div>
              </div>
              <div className="courseContentBoxBody">
                <p className="sessionDescription">
                  <div dangerouslySetInnerHTML={{ __html: removeTags(num?.Assignment.description) }} />
                </p>
              </div>
              <div className="courseContentFooter">
                <div className="submitDate">
                  {num.assignmentStatus !== "Submitted" ? (
                    <p>
                      Due Date:{" "}
                      <span>
                        {moment(num?.Assignment?.assignmentDueDate).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </p>
                  ) : (
                    "Completed"
                  )}
                </div>
                {(num.assignmentStatus === "Submitted" && num.obtainedMarks !== null) && (
                  <p className="successText">
                    Obtained Marks: {num?.obtainedMarks}/
                    {num?.Assignment?.totalPoint}
                  </p>
                )}
                {(num.assignmentStatus === "Submitted" && num.obtainedMarks === null) && (
                  <p className="dangerText">
                    Obtained Marks: Pending
                  </p>
                )}
                {
                  num?.comment !== null && 
                  <Button
                    classname={"primaryBtn"}
                    children={"View Remarks"}
                    onClick={() => handleViewRemarks(num.id)}
                  />
                }
                {num.assignmentStatus !== "Submitted" ? (
                  <Button
                    onClick={() => handleSubmit(num.id)}
                    classname={"primaryBtn"}
                    children={"Start"}
                  />
                ) : (
                  <Button
                    classname={"primaryBtn"}
                    children={"View"}
                    onClick={() => handleView(num.id)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CourseAssignment;
