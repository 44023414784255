import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let orgId;
let token;
let studentId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  studentId = localStorage.getItem("studentId");
};

// /org/:organizationId/student/:studentId/getStudentFeesDetails

export const getStudentFee = createAsyncThunk(
  "fee/getStudentFee",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          `/getStudentFeesDetails`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const submitBankUTRDetail = createAsyncThunk(
  "fee/submitBankUTRDetail",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          "student/" +
          studentId +
          "/feesDue/" +
          obj?.feeDueId +
          "/updateUtrNumber",

        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /org/:organizationId/student/:studentId/feesDue/:feeDueId/updateLoanRequest

export const sendBankLoanRequest = createAsyncThunk(
  "fee/sendBankLoanRequest",
  async ({ feeDueId, obj }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          "/feesDue/" +
          feeDueId +
          "/updateLoanRequest",

        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// /org/:organizationId/student/:studentId/feeDue/:feeDueId/getLoanProcessDetails

export const getLoanProcessData = createAsyncThunk(
  "fee/getLoanProcessData",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          "/feeDue/" +
          obj?.feeDue +
          `/getLoanProcessDetails`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// uploadDocumentData

export const uploadDocumentData = createAsyncThunk(
  "fee/uploadDocumentData",
  async ({ loanId, obj }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          orgId +
          "/student/" +
          studentId +
          "/loan/" +
          loanId +
          "/updateLoanProcessDocument",

        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const studentLoanRequest = createAsyncThunk(
  "fee/studentLoanRequest",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/org/" +
          "student/" +
          obj?.studentId +
          "/feesDue/" +
          obj?.feeDueId +
          "/updateAnonymousLoan",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const feeSlice = createSlice({
  name: "fee",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    feeList: [],
    isBankTransferSuccess: false,
    loanProcessData: {},
    approvalResponse: false,
    documentResponse: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      state.feeList = [];
      state.feeDetails = {};

      return state;
    },
    clearResponseState: (state) => {
      state.approvalResponse = false;
      state.documentResponse = false;

      return state;
    },
  },
  extraReducers: {
    [getStudentFee.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.feeDetails = payload.Data;
      return state;
    },
    [getStudentFee.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getStudentFee.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },

    [submitBankUTRDetail.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isBankTransferSuccess = true;
      // state.feeDetails = payload.Data;
      return state;
    },
    [submitBankUTRDetail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [submitBankUTRDetail.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },

    [submitBankUTRDetail.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.isBankTransferSuccess = true;
      // state.feeDetails = payload.Data;
      return state;
    },
    [submitBankUTRDetail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [submitBankUTRDetail.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },

    [getLoanProcessData.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.loanProcessData = payload.Data;
      return state;
    },
    [getLoanProcessData.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getLoanProcessData.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },

    [sendBankLoanRequest.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.approvalResponse = true;
      // state.isSuccess = true;
      // state.loanProcessData = payload.Data;
      return state;
    },
    [sendBankLoanRequest.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [sendBankLoanRequest.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },

    [uploadDocumentData.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.documentResponse = true;
      // state.isSuccess = true;
      // state.loanProcessData = payload.Data;
      return state;
    },
    [uploadDocumentData.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [uploadDocumentData.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },

    [studentLoanRequest.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.loanProcessData = payload.Data;
      return state;
    },
    [studentLoanRequest.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [studentLoanRequest.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

// /org/:organizationId/student/:studentId/feesDue/:feeDueId/updateLoanRequest

export const { clearState, clearResponseState } = feeSlice.actions;

export const feeData = (state) => state.feeDetails;
