import React from "react";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import "./QuizPopupStyle.css";
const QuizPopup = ({
  show,
  data,
  setSave,
  answer,
  setAnswer,
  index,
  time,
  handleBtn,
  addEmptyAnswer,
  setIndex,
  setShow
}) => {

  console.log(data, "by aj")


  return (
    <div className={`popupOuter ${show ? "active" : ""}`}>
      {index !== "" ? <div className="popupContainer quizPopupContainer">
        <div className="popupHeader">
          <p className="popupTitle">
            Question {index + 1}/{data?.FeedbackQuestions?.length}
          </p>
          <div className="timer">
            {/* {if(index!==""){setInterval(function () {console.log("gg")},1000) }} */}

            <p>
              Time: <span>{time}s</span>
            </p>
          </div>
          {/* <img src="../assets/icons/x-close.svg" alt="" /> */}
        </div>
        {/* <div className="resultSection">
                        <p>Your Result: <span>8/10 Points</span></p>
                </div> */}

        <div className="popupBody quizpPopupBody">
          <p className="question">
            {data?.FeedbackQuestions &&
              data?.FeedbackQuestions[index]?.questionName}
          </p>
          <div className="optionsOuter">
            {data?.FeedbackQuestions &&
              data?.FeedbackQuestions[index]?.FeedbackQuestionOptions.length >
              0 &&
              data?.FeedbackQuestions[index]?.FeedbackQuestionOptions.map(
                (num, val) => (
                  <div className="option" key={val}>
                    <input
                      type="radio"
                      value={num.id}
                      id={val}
                      name="radio-group"
                      onChange={() => setAnswer(num, data?.FeedbackQuestions[index]?.weightage)}
                      checked={
                        answer[index]?.feedbackOptionId[0]?.id === num?.id
                      }
                    />

                    <label for={val}>{num.value}</label>
                  </div>
                )
              )}

            {/* <div className="option correct">
                            <input type="radio" id="test1" name="radio-group" />
                            <label for="test1">The various intricacies involved in undergoing</label>
                        </div>

                        <div className="option wrong">
                            <input type="radio" id="test2" name="radio-group" />
                            <label for="test2">Undergoing a futures</label>
                        </div> */}
          </div>
        </div>
        <div className="popupFooter">
          {/* <PrimaryButton classname={'primaryBtn'} children={'Back'} isdisabled={index==0 ?true :false} onClick={()=>handleBtn("prev")}/> */}
          <div className="pointerScale">
            {data?.FeedbackQuestions?.map((item, num) => (
              <div
                className={`point ${num === index ? "active" : ""}`}
                key={num}
              ></div>
            ))}
          </div>
          {data?.FeedbackQuestions?.length !== index + 1 ? (
            <PrimaryButton
              classname={"primaryBtn"}
              children={"Next"}
              isdisabled={false}
              onClick={() => handleBtn("next")}
              
            />
          ) : (
            <PrimaryButton
              classname={"primaryBtn"}
              children={"Submit"}
              isdisabled={false}
              onClick={() => {
                addEmptyAnswer();
                setSave("done");
              }}
            />
          )}
        </div>
      </div> :
        <div className="courseQuizPopupContainer">
          <div className="popupHeader">
            <div className="popupHeaderLeft">
              <div className="successTag">Quiz</div>
              <div className="greyTag">Graded Quiz</div>
            </div>
              <div className="dangerTag"> Total Time: {data?.FeedbackQuestions?.reduce(
  (accumulator, currentValue) => accumulator + parseInt(currentValue.time),
  0
)} sec</div>
          </div>
          <div className="popupBody courseQuizBody">
            <p className="heading">{data?.title}</p>
            <p className="text"><span className="dangerText">Disclaimer :</span> {data?.description}
            </p>
            <p className="primaryText">Points to Remember</p>
            <ul className="pointsList">
              <li className="pointListItem">
                This quiz carries negative marking, 0.25 point reduce for each
                negative answer.
              </li>
              <li className="pointListItem">
                1 Point for every correct answer
              </li>
              <li className="pointListItem">Once you click to start quiz, your time starts from then</li>
            </ul>
          </div>
          <div className="popupFooter courseQuizFooter">
            <PrimaryButton classname={'darkBtn'} children={'Back'} onClick={()=>setShow(!show)}/>
            <PrimaryButton classname={'primaryBtn'} children={'Start Quiz'} onClick={()=>setIndex(0)}/>
          </div>
        </div>
        //       <div className="popupContainer">
        //        <div className="popupHeader">
        //           <p className="popupTitle">
        //           aj
        //           </p>


        //           </div>
        //         <div className="popupBody">
        //         {data?.title}
        //   {data?.description}
        //   <p>Total Questions: {data?.FeedbackQuestions?.length}</p>
        //   <div onClick={()=>setIndex(0)} style={{background:"blue",color:"#fff"}}>Start Quiz</div>
        // </div>

        //       </div>
      }


    </div>
  );
};

export default QuizPopup;
