import React from 'react'
import './SessionRecordStyle.css'
import BreadCrumbs from '../../../components/globalComponents/breadCrumbs/BreadCrumbs'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
const SessionRecord = () => {
    const layer = ['Session Record', '001']
    return (
        <>
            <div className="pageHeader">
                <BreadCrumbs layers={layer} />
                <div></div>
                <div></div>
            </div>
            <div className="sessionRecordContainerOuter">
                <div className="sessionRecordContainer">
                    <div class="ticketDetailsHeader">
                        <div class="contentLeft">
                            <img src="../assets/icons/annotation-alert.svg" alt="" />
                            <p class="tktNumber">Session Id: 001</p>
                        </div>
                        <div class="successTag">Approved</div>
                        {/* <div className="greyTag">Pending</div> */}
                        {/* <div className="dangerTag">Rejected</div> */}
                        {/* <div className="warningTag">Cancelled</div> */}
                    </div>
                    <div class="ticketDetailsBody">
                        <p class="ticketName">Coaching Session 1 </p>
                        <p class="ticketDescription">
                            Agenda is dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                        </p>
                        <div class="ticketTable">
                            <div class="ticketTableElement">
                                <p class="heading">Scheduled Date:</p>
                                <p class="content">16-September-2022</p>
                            </div><div class="ticketTableElement">
                                <p class="heading">Scheduled Time:</p>
                                <p class="content">1 AM</p>
                            </div>
                            <div class="ticketTableElement">
                                <p class="heading">Raised On:</p>
                                <p class="content">Satinder Singh</p>
                            </div>
                        </div>
                        <PrimaryButton classname={'successBtn'} children={'Start Session'} />
                    </div>
                </div>
                <div className="sessionRecordContainer">
                    <div class="ticketDetailsHeader">
                        <div class="contentLeft">
                            <p class="tktNumber">SOS</p>
                        </div>
                    </div>
                    <div className="ticketDetailsBody">
                        <p className="greyText">-- no SOS Raised --</p>
                        <p className="ticketDescription">Dear Student, Since I urgently have to travel I will not be available for the session. I request the session to be rescheduled soon.</p>
                        <PrimaryButton classname={'dangerOutlineBtnSmall'} children={'Raise SOS'} />
                    </div>
                </div>
                <div className="sessionRecordContainer">
                    <div class="ticketDetailsHeader">
                        <div class="contentLeft">
                            <p class="tktNumber">Feedback</p>
                        </div>
                    </div>
                    <div className="ticketDetailsBody">
                        <div className="feedbackQnA">
                            <p className="feedbackQuestion">How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?</p>
                            <p className="feedbackAnswer">5</p>
                        </div>
                        <div className="feedbackQnA">
                            <p className="feedbackQuestion">How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?</p>
                            <p className="feedbackAnswer">5</p>
                        </div>
                        <div className="feedbackQnA">
                            <p className="feedbackQuestion">How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?</p>
                            <p className="feedbackAnswer">5</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SessionRecord