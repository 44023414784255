import { Route, Routes } from "react-router-dom";

import "./App.css";
import Header from "./components/layout/header/Header";
import SideMenu from "./components/layout/sideMenu/SideMenu";
import CourseDetail from "./pages/courseDetails/CourseDetail";
import Login from "./pages/login/Login";
import DashBoard from "./pages/dashBoard/DashBoard";
import MyCourses from "./pages/myCourses/MyCourses";
import ClubList from "./pages/clubModule/clubList/ClubList";
import ClubDetails from "./pages/clubModule/clubDetails/ClubDetails";
import Notification from "./components/notificationComponent/Notification";
import Attendance from "./pages/Attendance/Attendance";
import Notifications from "./pages/notifiactions/Notifications";
import Announcement from "./pages/announcements/Announcement";
import AttendanceList from "./pages/Attendance/AttendanceList";
import CreateClub from "./pages/clubModule/createClub/CreateClub";
import FeedbackList from "./pages/feedback/feedbackList/FeedbackList";
import FeedBackPreview from "./pages/feedback/feedBackPreview/FeedBackPreview";
import EditClub from "./pages/clubModule/editClub/EditClub";
import Grades from "./pages/grades/Grades";
import HelpDesk from "./pages/help/helpDesk/HelpDesk";
import CreateHelpTicket from "./pages/help/createHelpTicket/CreateHelpTicket";
import TicketRecord from "./pages/help/ticketRecord/TicketRecord";
import LeaveList from "./pages/leave/leaveList/LeaveList";
import LeaveDetails from "./pages/leave/leaveDetails/LeaveDetails";
import LeaveApplication from "./pages/leave/leaveApplication/LeaveApplication";
import EventList from "./pages/events/eventList/EventList";
import CreateEvent from "./pages/events/createEvent/CreateEvent";
import EventDetails from "./pages/events/eventDetails/EventDetails";
import Profile from "./pages/profile/Profile";
import Fee from "./pages/fee/Fee";
import MeetingList from "./pages/meeting/meetingList/MeetingList";
import CreateMeeting from "./pages/meeting/createMeeting/CreateMeeting";
import SessionRecord from "./pages/meeting/sessionRecord/SessionRecord";
import PrivateRoute from "./helper/PrivateRoute";
import { useSelector } from "react-redux";
import { userSliceFunction } from "./reducers/userSlice";
import SubmitFeedback from "./pages/feedback/submitFeedback/SubmitFeedback";
import BookMeetingList from "./pages/bookMeetingModule/BookMeetingList";
import MeetDetails from "./pages/bookMeetingModule/MeetDetails";
import CreateMeetingRoom from "./pages/bookMeetingModule/CreateMeetingRoom";
import Leaderboard from "./pages/leaderboard/Leaderboard";
import EmailResponse from "./pages/email/emailResponse/EmailResponse";
import EmailToCandidates from "./pages/email/emailToCandidates/EmailToCandidates";
import InterviewScheduler from "./pages/interviewScheduler/InterviewScheduler";
import CoursePreference from "./pages/courseBidding/coursePreference/CoursePreference";
import CourseRegisteration from "./pages/courseBidding/courseRegisteration/CourseRegisteration";
import LogoutPopup from "./components/popups/LogoutPopup/LogoutPopup";
import { useState } from "react";
import CourseAssignment from "./pages/courseDetails/CourseAssignment";
import MentorCallScheduler from "./pages/mentorCallScheduler/MentorCallScheduler";

function App() {
  const pathname = window.location.pathname;
  const { token } = useSelector(userSliceFunction);
  const [logoutToggler, setLogoutToggler] = useState(false);

  return (
    <div className="appOuter">
      {pathname === "/login" || token === null ? (
        ""
      ) : (
        <Header setLogout={setLogoutToggler} />
      )}
      <LogoutPopup
        isOpen={logoutToggler}
        onClose={() => setLogoutToggler(false)}
        onLogout={() => {
          localStorage.clear();
          window.location.reload();
        }}
        title={"Logout"}
        boldText={"Are you sure you want to logout?"}
        btnType={"successBtn"}
        btnText={"Continue"}
      />
      <SideMenu />
      <Notification />
      <div className="appContainer">
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/interviewScheduler"
            element={<InterviewScheduler />}
          />

          <Route
            exact
            path="/scheduleCallWithMentor"
            element={<MentorCallScheduler />}
          />
          <Route
            exact
            path="/emailToCandidates"
            element={<EmailToCandidates />}
          />
          <Route exact path="/emailResponse" element={<EmailResponse />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/feedbackList" element={<FeedbackList />} />
            <Route
              exact
              path="/FeedBackPreview/:id"
              element={<FeedBackPreview />}
            />
            <Route
              exact
              path="/submitFeedback/:id"
              element={<SubmitFeedback />}
            />
            <Route exact path="/" element={<DashBoard />} />
            <Route exact path="/myCourses" element={<MyCourses />} />
            <Route exact path="/courseDetails" element={<CourseDetail />} />
            <Route
              exact
              path="/courseAssignment"
              element={<CourseAssignment />}
            />
            <Route exact path="/clubList" element={<ClubList />} />
            <Route exact path="/clubDetails" element={<ClubDetails />} />
            <Route exact path="/createClub" element={<CreateClub />} />
            <Route exact path="/editClub" element={<EditClub />} />
            <Route exact path="/attendance" element={<Attendance />} />
            <Route exact path="/attendanceList" element={<AttendanceList />} />
            <Route exact path="/notifications" element={<Notifications />} />
            <Route exact path="/announcement" element={<Announcement />} />

            {/* <Route exact path='/profile' element={<Profile />} /> */}
            <Route exact path="/grades" element={<Grades />} />
            <Route exact path="/help" element={<HelpDesk />} />
            <Route
              exact
              path="/createHelpTicket"
              element={<CreateHelpTicket />}
            />

            <Route exact path="/ticketRecord/:id" element={<TicketRecord />} />
            {/* <Route
              exact
              path="/emailToCandidates"
              element={<EmailToCandidates />}
            /> */}
            {/* <Route exact path="/emailResponse" element={<EmailResponse />} /> */}
            <Route exact path="/leaveList" element={<LeaveList />} />
            <Route exact path="/leaveDetails" element={<LeaveDetails />} />
            <Route
              exact
              path="/leaveApplication"
              element={<LeaveApplication />}
            />
            <Route exact path="/eventList" element={<EventList />} />
            <Route exact path="/createEvent" element={<CreateEvent />} />
            <Route exact path="/eventDetails" element={<EventDetails />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/feeList" element={<Fee />} />
            <Route exact path="/meetingList" element={<MeetingList />} />
            <Route exact path="/createMeting" element={<CreateMeeting />} />
            <Route exact path="/sessionRecord" element={<SessionRecord />} />

            <Route
              exact
              path="/BookMeetingList"
              element={<BookMeetingList />}
            />
            <Route exact path="/BookMeetingDetails" element={<MeetDetails />} />
            <Route
              exact
              path="/createMeetingRoom"
              element={<CreateMeetingRoom />}
            />
            <Route exact path="/leaderboard" element={<Leaderboard />} />
            {/* <Route
              exact
              path="/interviewScheduler"
              element={<InterviewScheduler />}
            /> */}

            <Route
              exact
              path="/coursePreference"
              element={<CoursePreference />}
            />
            <Route
              exact
              path="/courseRegisteration"
              element={<CourseRegisteration />}
            />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
