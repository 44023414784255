import React from 'react'
import './UploadImagePopupStyle.css'
import CustomUploadBox from '../../globalComponents/customUploadBox/CustomUploadBox'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'

const UploadImagePopup = () => {
    return (
        <div className="popupOuter active">
            <div className="uploadImageContainer">
                <div className="popupHeader">
                    <p className="popupTitle">Uplaod Image</p>
                    <img src="../assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody">
                    <CustomUploadBox label={'Upload Image'}/>
                </div>
                <div className="popupBtnGrp">
                    <PrimaryButton classname={'primaryBtn'} children={'Upload Image'}/>
                    <PrimaryButton classname={'dangerBtn'} children={'Cancel'} />
                </div>
            </div>
        </div>
    )
}

export default UploadImagePopup