import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import './ConfirmationPopupStyle.css'
const ConfirmationPopup = ({title,boldText,text,btnType,btnText,show,handleClose,btnHandler}) => {
    return (
        <div className={`popupOuter ${show?"active":""}`}>
            <div className="confirmationPopupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">{title}</p>
                    <img src="../assets/icons/x-close.svg" alt="" onClick={()=>handleClose(false)}/>
                </div>
                <div className="popupBody">
                    <p className="popupText"> <b>{boldText}</b>{' '}{text} </p>
                    <div className="popupBtnGrp">
                        <PrimaryButton classname={btnType} children={btnText} onClick={btnHandler} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationPopup

  