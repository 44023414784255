import React, { useEffect } from "react";
import { useState } from "react";
import {
  emailResponseSliceData,
  getAllSentOfferEmailsRecord,
  updateOfferEmailStatus,
  deleteCandidateOfferDetails,
} from "../../../reducers/emailSlice";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/globalComponents/PageLoader/PageLoader";
// import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import StudentEmailResponsePopup from "../../../components/popups/studentEmailResponsePopup/StudentEmailResponsePopup";
import { useNavigate } from "react-router-dom";
import RejectionPopup from "../../../components/popups/rejectionPopup/RejectionPopup ";

const EmailResponse = () => {


  const [show, setShow] = useState(false)
  // const [clearSearch, setClearSearch] = useState(false)
  const [id, setId] = useState(null)
  const [menuId, setMenuId] = useState(null);
  const [search, setSearch] = useState("")
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  // const [isLoading, setIsLoading] = useState(false);
  const [showMoreLoader, setShowMoreLoader] = useState(false);
  const [pageNo, setPageNo] = useState("1");
  const [loaded, setLoaded] = useState(false);
  // const [applyFilter, setApplyFilter] = useState(false);
  const [emailListArray, setEmailListArray] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState("10");

  const dispatch = useDispatch();
  const { isFetching, isResponse, isDelete, emailDataCount, emailData } =
    useSelector(emailResponseSliceData);


  useEffect(() => {
    console.log(emailData);
    dispatch(getAllSentOfferEmailsRecord({ search }));
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   console.log(emailData);
  //   dispatch(getAllSentOfferEmailsRecord({ search }));
  //   // eslint-disable-next-line
  // }, [clearSearch]);

  //UserResponse
  useEffect(() => {
    console.log(emailData);
    if (isResponse) dispatch(getAllSentOfferEmailsRecord({ search }));
    // eslint-disable-next-line
  }, [isResponse]);

  //DeleteResponse
  useEffect(() => {
    console.log(emailData)
    if (isDelete) dispatch(getAllSentOfferEmailsRecord({ search }))
    // eslint-disable-next-line
  }, [isDelete])

  //pagenation
  useEffect(() => {
    var params = {
      searchTitle: search,
      pageNo: pageNo,
      pageSize: pageSize,
    }
    dispatch(getAllSentOfferEmailsRecord(params));
    // eslint-disable-next-line
  }, [pageNo, pageSize])

  useEffect(() => {
    // console.log("inside 1 use effect");
    setPageNo("1");
    setLoaded(false);
  }, [pageSize]);

  useEffect(() => {
    if (emailData) {
      console.log("checking loaded status", loaded);
      if (loaded === true) {
        setEmailListArray((prev) => [...prev, ...emailData]);
        setLoaded("done");
      } else if (loaded === false) {
        setEmailListArray(emailData);
      } else {
        console.log(null);
      }
      setLoaded(false);
    }
    // eslint-disable-next-line
  }, [emailData]);


  // useEffect(() => {
  //     if (search) dispatch(getAllSentOfferEmailsRecord({ search }))
  // }, [search])

  const openActionMenu = (id) => {
    if (menuId === id) {
      setMenuId(null);
    } else {
      setMenuId(id);
    }
  };
  const closeActionMenu = () => {
    setMenuId(null);
  };

  const submitHandle = (isResponse) => {
    const obj = { id, Response: isResponse };

    dispatch(updateOfferEmailStatus(obj));
  };

  const deleteHandle = (isDelete) => {
    console.log(id);
    setShowDeletePopup(false)
    const obj = { id, isDelete }
    dispatch(deleteCandidateOfferDetails(obj));
  };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Backspace") {
  //     const obj = { search }
  //     dispatch(getAllSentOfferEmailsRecord(obj));
  //   }
  // };

  //SearchBar
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (search !== "") {
        var params = {
          searchTitle: search,
          pageNo: pageNo,
          pageSize: pageSize,
        }
        dispatch(getAllSentOfferEmailsRecord( params ));
        // console.log('search',e.key);
        //  setSearch(e.target.value);
      }
    }
  };

  const ClearInput = () => {
    setSearch("")
    // setClearSearch(!clearSearch)
    var params = {
      searchTitle: "",
      pageNo: pageNo,
      pageSize: pageSize,
    }
    dispatch(getAllSentOfferEmailsRecord( params ));
  }

  const handleLoadClick = () => {
    if (pageNo === Math.ceil(emailDataCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
    setShowMoreLoader(true);
    setLoaded(true);
    //  const obj = { search }
    // dispatch(getAllSentOfferEmailsRecord());
  };



  return (
    <>
      {isFetching && <PageLoader />}
      <StudentEmailResponsePopup
        show={show}
        handleClose={setShow}
        submitHandle={submitHandle}
      />

      <RejectionPopup
        title={"Delete Forever"}
        boldText={"Are you sure you want to delete it?"}
        show={showDeletePopup}
        onClick={deleteHandle}
        handleClose={() => setShowDeletePopup(false)}
      />

      <RejectionPopup title={'Delete Forever'}
        boldText={'Are you sure you want to delete it?'}
        show={showDeletePopup} onClick={deleteHandle}
        handleClose={() => setShowDeletePopup(false)} />

      <div className="pageHeader">
        <div className="backBtn" onClick={() => navigate('/emailToCandidates')}>
          <img src="../assets/icons/back-btn.svg" alt="" />
          Back To Page
        </div>
        <div></div>
        <div></div>
      </div>

      <div className="pageHeader">
        <p className="componentHeading"></p>
        <div className="pageTogglers">
          <div className="pageToggler active"></div>
        </div>
        <div></div>
      </div>
      <div className="filterBar">
        <div className="filterBarLeft">

          <div className="searchBox">
            {/* <img src="../assets/icons/search-sm.svg" alt="" /> */}
            <input type="text" className="searchInput" placeholder='Search ...' value={search} onChange={e => setSearch(e.target.value)} onKeyPress={handleKeyPress} />

            {search.length === 0 ? (<img src="../assets/icons/search-sm.svg" alt="" />) : (<img src="../assets/icons/x-close.svg" onClick={ClearInput} alt="" className='pointer' />)}
            {/* 
            {search.length === 0 ? (
              <img src="../assets/icons/search-sm.svg" alt="" />
            ) : (
              <img
                src="../assets/icons/x-close.svg"
                onClick={ClearInput}
                alt=""
                className="pointer"
              />
            )} */}
          </div>
          <div className="total-data-desc">
            Showing {emailListArray?.length} out of {emailDataCount}
          </div>

        </div>

        {/* <button className="fiterBtn" onClick={() => setOpenFilters(!openFilters)}> <img src="../assets/icons/filter-lines.svg" alt="" /> Filters</button> */}
        <select
          id="type"
          onChange={(e) => {
            setPageSize(e.target.value);
            // setApplyFilter(true);
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
        </select>
      </div>
      <div className="helpDeskTableOuter">
        <table className="helpDeskTable" cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>Serial No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Company</th>
              <th>Mail Sent</th>
              <th>User Response</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {emailListArray &&
              emailListArray?.map((items, index) => (
                <tr key={items.id}>
                  <td>{index + 1}</td>
                  <td>{items.name}</td>
                  <td>{items.email}</td>
                  <td>{items.company}</td>
                  <td>{items.isMailed === false ? "False" : "True"}</td>
                  <td>{items.isResponse === false ? "False" : "True"}</td>
                  {/* <td><PrimaryButton children={'Action'} classname={'secondaryBtnSmall'} onClick={() => { setShow(true); setId(items.id) }} /></td> */}
                  <td>
                    <button
                      className={`actionBtn ${menuId === index ? "active" : ""
                        }`}
                      onClick={() => openActionMenu(index)}
                      onBlur={() => closeActionMenu(null)}
                    >
                      <img src="../assets/icons/actionBtn.svg" alt="" />
                      <ul className="actionDropdownList">
                        <li
                          className="actionDropdownListItem"
                          onClick={() => {
                            setShow(true);
                            setId(items.id);
                          }}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.3523 1.85226C13.4004 0.804168 15.0997 0.804169 16.1478 1.85227C17.1959 2.90036 17.1959 4.59967 16.1478 5.64776L6.24305 15.5525C6.23207 15.5634 6.22124 15.5743 6.21054 15.585C6.02157 15.7742 5.87275 15.9231 5.69683 16.0391C5.54169 16.1414 5.37343 16.2223 5.19663 16.2795C4.99615 16.3444 4.78685 16.3676 4.52109 16.397C4.50604 16.3986 4.49081 16.4003 4.4754 16.402L1.93712 16.6841C1.76729 16.7029 1.59809 16.6436 1.47726 16.5228C1.35643 16.4019 1.29707 16.2327 1.31594 16.0629L1.59797 13.5246C1.59969 13.5092 1.60137 13.494 1.60304 13.4789C1.63243 13.2132 1.65558 13.0039 1.72048 12.8034C1.77772 12.6266 1.85859 12.4583 1.96089 12.3032C2.0769 12.1273 2.22586 11.9785 2.41501 11.7895C2.42573 11.7788 2.43658 11.7679 2.44755 11.757L12.3523 1.85226ZM15.3523 2.64776C14.7435 2.039 13.7565 2.039 13.1478 2.64776L3.24305 12.5525C3.00648 12.789 2.94522 12.854 2.90007 12.9225C2.85357 12.993 2.81681 13.0695 2.7908 13.1499C2.76554 13.2279 2.75304 13.3163 2.71609 13.6489L2.51171 15.4883L4.35116 15.2839C4.68367 15.247 4.77212 15.2345 4.85014 15.2092C4.93051 15.1832 5.00699 15.1464 5.07751 15.0999C5.14597 15.0548 5.21098 14.9935 5.44755 14.757L15.3523 4.85227C15.961 4.24351 15.961 3.25652 15.3523 2.64776Z"
                              fill="#171717"
                            />
                          </svg>
                          User Response
                        </li>

                        <li
                          className="actionDropdownListItem delete"
                          onClick={() => {
                            setShowDeletePopup(true);
                            setId(items.id);
                          }}
                        >
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.10225 4.60225C4.32192 4.38258 4.67808 4.38258 4.89775 4.60225L9 8.70451L13.1023 4.60225C13.3219 4.38258 13.6781 4.38258 13.8977 4.60225C14.1174 4.82192 14.1174 5.17808 13.8977 5.39775L9.79549 9.5L13.8977 13.6023C14.1174 13.8219 14.1174 14.1781 13.8977 14.3977C13.6781 14.6174 13.3219 14.6174 13.1023 14.3977L9 10.2955L4.89775 14.3977C4.67808 14.6174 4.32192 14.6174 4.10225 14.3977C3.88258 14.1781 3.88258 13.8219 4.10225 13.6023L8.20451 9.5L4.10225 5.39775C3.88258 5.17808 3.88258 4.82192 4.10225 4.60225Z"
                              fill="#DF2935"
                            />
                          </svg>
                          Delete
                        </li>
                      </ul>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {emailListArray?.length !== emailDataCount && (
        <div className="loadMoreRow">
          <button
            // className={`${isLoading && showMoreLoader
            className={`${showMoreLoader
              ? "primary-btn-a active"
              : "primary-btn-a"
              }`}
            onClick={handleLoadClick}
          >
            <img src="../assets/icons/loader icon-01.png" alt="loading-img"/> Show More
          </button>
        </div>
      )}
    </>
  );
};

export default EmailResponse;
