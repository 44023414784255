import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import Notification from '../../notificationComponent/Notification'
import SideMenu from '../sideMenu/SideMenu';
import './HeaderStyle.css';

const Header = ({ setLogout }) => {
  const [showNotifications, setShowNotifications] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const Term = localStorage.getItem('curTerm')
  const programName = localStorage.getItem('programName')
  const cohort = localStorage.getItem('cohort')

  const handleCloseNotification=()=>{
    setShowNotifications(false)
  }
  return (
    <>
      <Notification isOpened={showNotifications} handleCloseNotification={handleCloseNotification} />
      <SideMenu setIsOpened={setShowMenu} isOpened={showMenu} setLogout={setLogout} />
      <div className="headerOuter">
        <div className="headerLeft">
          <img src="../assets/icons/hamburgerMenuIcon.svg" alt="" className={`pointer hamburger ${showMenu?'active':''}`} onClick={()=>setShowMenu(!showMenu)}/>
          
          <NavLink  to="/">
          <img src="../assets/icons/muLogoWhite.svg" alt="" />
          </NavLink>
        </div>
        <div className="headerCenter">
          <p className="programName">{programName} ({cohort})</p>
          <p className="darkTag">Term {Term}</p>
        </div>
        <div className="headerRight">
          {/* <img src="../assets/icons/bell-02.svg" alt="" className='iconBg pointer' onClick={() => setShowNotifications(!showNotifications)} /> */}
          <img src="https://events.powerathletehq.com/wp-content/uploads/2018/06/grey-person-coming-soon-e1529779699986.jpg" alt="" className="userImg" />
          <div className="studentDetails">
            <p className="studentName">{localStorage.getItem("name")}</p>
            <p className="studentId">UID: {localStorage.getItem("uid")}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header