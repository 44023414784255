import React from "react";
import "./ClubDetailsStyle.css";
import PrimaryButtonImg from "../../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg";
import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";

const ClubDetails = () => {
  // const [showTable, setShowTable] = useState(true)
  return (
    <div className="clubDetailsContainer">
      <div className="pageHeader">
        <p className="componentHeading"></p>
        <div></div>
        <div>
          <PrimaryButtonImg
            classname={"primaryBtn"}
            children={"Create"}
            img={"../assets/icons/message-question-square-sml.svg"}
          />
        </div>
      </div>
      <div className="clubDetailsContainerOuter">
        <div className="clubDetailsCenterContainer">
          <div className="clubHeaderOuter">
            <div className="clubHeaderImg">
              <img src="" alt="" />
            </div>
            <div className="clubBody">
              <img
                src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
                className="clubAvtar"
              />
              <div className="clubDetailsOuter">
                <div className="clubDetail">
                  <div className="clubDetailHeader">
                    <p className="clubName">Harvard business Club</p>
                    <div className="successTag">Music Club</div>
                  </div>
                  <p className="clubInfo">
                    At Harvard Business Review, we believe in management. If the
                    world’s organizations and institutions were run more
                    effectively, if our leaders made better decisions, if people
                    worked more productively,
                  </p>
                </div>
                <div className="clubDetail">
                  <PrimaryButton
                    classname={"darkSecondaryOutlineBtnSmall"}
                    children={"Guiding Document"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clubBodyOuter">
            <div className="clubBodyBox">
              <div className="boxHeader">
                <p className="toggler active">Upcoming Events</p>
                <p className="toggler">Past Events</p>
              </div>
              <div className="boxBody">
                <table cellPadding={0} cellSpacing={0}>
                  <tbody>
                    <tr>
                      <td>Learn Website Wireframing with Shravan Tickoo</td>
                      <td>21st Jan 2022</td>
                    </tr>
                    <tr>
                      <td>Learn Website Wireframing with Shravan Tickoo</td>
                      <td>21st Jan 2022</td>
                    </tr>
                    <tr>
                      <td>Learn Website Wireframing with Shravan Tickoo</td>
                      <td>21st Jan 2022</td>
                    </tr>
                    <tr>
                      <td>Learn Website Wireframing with Shravan Tickoo</td>
                      <td>21st Jan 2022</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="boxFooter">
                <p className="footerText">See all upcoming events</p>
                <img src="../assets/icons/chevron-right-double.svg" alt="" />
              </div>
            </div>
            <div className="clubBodyBox">
              <div className="presidentInfoBox">
                <img
                  src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt=""
                />
                <div className="presidentInfo">
                  <div className="nameBox">
                    <p className="name">Rahul Kapoor</p>
                    <div className="successTag">President</div>
                  </div>
                  <p className="programName">
                    Programme in Finance and Accounting | 2018-2020 |
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubDetails;
