import React from 'react'
import './NotificationStyle.css'
const Notification = ({ isOpened, handleCloseNotification }) => {
    return (
        <div className={`notificationOuter ${isOpened ? 'active' : ''}`}>
            <div className="notificationContainer">
                <div className="notificationHeader">
                    <p className="heading">Notifications</p>
                    {/* <img src="../assets/icons/x-close.svg" alt="" onClick={handleCloseNotification} className='pointer' /> */}
                    <p className="dangerTextUnderline" onClick={handleCloseNotification}>Mark all as read</p>
                </div>
                <div className="notificationBody">
                    <ul className="notificationList">
                        <li className="notification">
                            <img src="../assets/icons/Club.svg" alt="" />
                            <div className="notificationContent">
                                <p className="notificationName">Real-time Feedback Notifications</p>
                                <p className="notificationDescription">Get real-time feedback alerts on email and SMS for new and negative feedbacks. </p>
                            </div>
                        </li>
                        <li className="notification">
                            <img src="../assets/icons/Club.svg" alt="" />
                            <div className="notificationContent">
                                <p className="notificationName">Real-time Feedback Notifications</p>
                                <p className="notificationDescription">Time Table for Forthcoming Examinations ...</p>
                            </div>
                        </li>
                        <li className="notification">
                            <img src="../assets/icons/Club.svg" alt="" />
                            <div className="notificationContent">
                                <p className="notificationName">Real-time Feedback Notifications</p>
                                <p className="notificationDescription">getting loads of notifications from a club. </p>
                            </div>
                        </li>
                        <li className="notification">
                            <img src="../assets/icons/Club.svg" alt="" />
                            <div className="notificationContent">
                                <p className="notificationName">Real-time Feedback Notifications</p>
                                <p className="notificationDescription">messages from the InfoSphere MDM to be sent </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="notificationFooter">
                    <p className="NotificationFooterText">Sell all Notifications</p>
                    <img src="../assets/icons/chevron-right-double.svg" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Notification