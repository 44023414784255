import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let organizationId;
let token ;
let studentId;

let setTokenValues = () => {
  organizationId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  studentId = localStorage.getItem("studentId");
};

export const leaveForm = createAsyncThunk(
  "submitted/leaveForm",
  async (
    { typeOfLeave, durationOfLeave, startDate, endDate, reason, uploadDocument, fileName },
    thunkAPI
  ) => {
    try {
      setTokenValues();
      const payload = {
        leaveType: typeOfLeave,
        dayType: durationOfLeave,
        fromDate: startDate,
        toDate: endDate,
        Reason: reason,
        organizationId: organizationId,
        studentId: studentId,
        uploadDocument:uploadDocument,
        fileName:fileName
      };

      const response = await fetch(
        `${environment.baseURL}/api/std/org/${organizationId}/student/${studentId}/leaveRequest`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        console.log("inside data");
        return data;
      } else {
        console.log("api rejected");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getMissingData = createAsyncThunk(
  "leave/getMissingData",
  async ({startTime,endTime}, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${environment.baseURL}/api/std/org/${organizationId}/student/${studentId}/getMissingSessions?startTime=${startTime}&endTime=${endTime}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getLeaveData = createAsyncThunk(
  "leave/getLeaveData",
  async ({type,status}, thunkAPI) => {
    try {
      setTokenValues();
     
      // console.log(LeaveType,LeaveStatus,"slice")
      const response = await fetch(
        `${environment.baseURL}/api/std/org/${organizationId}/student/${studentId}/getStudentAllLeaveRequests?status=${status}&type=${type}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const leaveCancel = createAsyncThunk(
  "cancel/leaveCancel",
  async (
    obj,
    thunkAPI
  ) => {
    try {
      setTokenValues();
      const payload = {
        isCancelled:true,
        leaveStatus:"cancelled"
      };

      const response = await fetch(
        `${environment.baseURL}/api/std/org/${organizationId}/student/${studentId}/leaveId/${obj}/leaveRequest`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        console.log("inside data");
        return data;
      } else {
        console.log("api rejected");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getParticularLeaveDetails = createAsyncThunk(
  "leave/getParticularLeaveDetails",
  async (id, thunkAPI) => {

    console.log(id,"sllll")
    try {
      setTokenValues();
     
      // console.log(LeaveType,LeaveStatus,"slice")
      const response = await fetch(
        `${environment.baseURL}/api/std/org/${organizationId}/leaveId/${id}/getParticularLeaveDetails`,
        
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);





export const leaveSlice = createSlice({
  name: "leave",
  initialState: {
    typeOfLeave: "",
    durationOfLeave: "",
    startDate: "",
    endDate: "",
    selectTerm: "",
    reason: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    getData: [],
    leaveData: [],
    missingLactures:[],
    // token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [leaveForm.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.token = payload.Token;
      return state;
    },
    [leaveForm.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [leaveForm.pending]: (state) => {
      state.isFetching = true;
    },
    [getParticularLeaveDetails.fulfilled]: (state, { payload }) => {
      console.log("particultar", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.getData = payload;
      return state;
    },
    [getParticularLeaveDetails.rejected]: (state, { payload }) => {
      console.log("payload1", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getParticularLeaveDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [getLeaveData.fulfilled]: (state, { payload }) => {
      console.log("printing payload2", payload);
      state.isFetching = false;
      state.leaveData = payload;
      return state;
    },
    [getLeaveData.rejected]: (state, { payload }) => {
      console.log("payload2", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getLeaveData.pending]: (state) => {
      state.isFetching = true;
    },
    [getMissingData.fulfilled]: (state, { payload }) => {
      console.log("printing payload 2 lactures", payload);
      state.isFetching = false;
      state.missingLactures = payload;
      return state;
    },
    [getMissingData.rejected]: (state, { payload }) => {
      console.log("payload2", payload);
      state.isFetching = false;
      state.isError = true;
       state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMissingData.pending]: (state) => {
      state.isFetching = true;
    },

    [leaveCancel.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [leaveCancel.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
     state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [leaveCancel.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = leaveSlice.actions;

export const userSliceFunction = (state) => state.leave;
