import React from "react";
import "./BreadCrumbsStyle.css";
const BreadCrumbs = ({layers}) => {
  // const layers = [
  //   "Layer 1",
  //   "Layer 2",
  //   "Layer 3",
  //   "Layer 4",
  //   "Layer 5",
  //   "Layer 6",
  // ];
  return (
   
        <div className="breadCrumbOuter">
          {layers?.map((layer,i) => {
            return (
              <div className="breadCrumb" key={i}>
                <p>{layer}</p>
                <span>/</span>
              </div>
            );
          })}
        </div>
     
  );
};

export default BreadCrumbs;
