import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let token;

let setTokenValues = () => {
  token = localStorage.getItem("token");
};

export const getAvailableInterviewSlots = createAsyncThunk(
  "interview/getAvailableInterviewSlots",
  async ({ programId, date, applicantId }, thunkAPI) => {
    console.log(date, "date");
    try {
      setTokenValues();
      const response = await fetch(
        `${environment.baseURL}/api/org/programId/${programId}/getAvailableInterviewSlots?date=${date}&applicantId=${applicantId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const bookInterviewSlots = createAsyncThunk(
  "interview/bookInterviewSlots",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/bookInterviewSlots",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getMentorCallAvailableSlots = createAsyncThunk(
  "interview/getMentorCallAvailableSlots",
  async ({ programId, date, applicantId }, thunkAPI) => {
    console.log(date, "date");
    try {
      setTokenValues();
      const response = await fetch(
        `${environment.baseURL}/api/org/programId/${programId}/getmentorshipCallAvailableInterviewSlots?date=${date}&applicantId=${applicantId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const bookMentorCallSlots = createAsyncThunk(
  "interview/bookMentorCallSlots",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/bookmentorshipCallInterviewSlots",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const interviewSlice = createSlice({
  name: "interview",
  initialState: {
    interviewSlots: [],
    bookSlotResponse: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [getAvailableInterviewSlots.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.interviewSlots = payload;
      state.bookSlotResponse = payload?.totalSlotsBooked ?? [];
      return state;
    },
    [getAvailableInterviewSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAvailableInterviewSlots.pending]: (state) => {
      state.isFetching = true;
    },
    [bookInterviewSlots.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.bookSlotResponse = payload;
      state.isFetching = false;
      return state;
    },
    [bookInterviewSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [bookInterviewSlots.pending]: (state) => {
      state.isFetching = true;
    },

    /**for Mentor */
    /**START */
    [getMentorCallAvailableSlots.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.interviewSlots = payload;
      state.bookSlotResponse = payload?.totalSlotsBooked ?? [];
      return state;
    },
    [getMentorCallAvailableSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getMentorCallAvailableSlots.pending]: (state) => {
      state.isFetching = true;
    },
    [bookMentorCallSlots.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.bookSlotResponse = payload;
      state.isFetching = false;
      return state;
    },
    [bookMentorCallSlots.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [bookMentorCallSlots.pending]: (state) => {
      state.isFetching = true;
    },
    /**END */
  },
});

export const { clearState } = interviewSlice.actions;

export const interviewSliceData = (state) => state.interview;
