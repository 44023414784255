import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";



let orgId;
let token;
let studentId;
// let programId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  studentId = localStorage.getItem("studentId");
};

export const loginUser = createAsyncThunk(
  "student/loginUser",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };

      // console.log("nvnfjkvnfknvjfnfv fv f", payload);
      const response = await fetch(
        // "https://test-epicapi.herokuapp.com/api/org/admin/auth",
        `${environment.baseURL}/api/std/student/login`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        console.log("inside data");
        const name = data.Data.firstName + " " + data.Data.lastName
        localStorage.setItem("token", data.Token);
        localStorage.setItem("curTerm", data.Term?.term);
        localStorage.setItem("programName", data.Term?.ProgramDetail?.Program?.programName);
        localStorage.setItem("cohort", data.Term?.ProgramDetail?.name);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("programId", data.Data.programId);
        localStorage.setItem("Email", data.Data.officialEmail);
        localStorage.setItem("orgId", data.Data.organizationId);
        localStorage.setItem("studentId", data.Data.id);
        localStorage.setItem("name",name);
        localStorage.setItem("uid",data.Data.registrationNumber)
        console.log("before return");
        // localStorage.setItem("adminId", data.Data.id);
        return data;
      } else {
        console.log("api rejected");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


// Student Detail
export const getStudentDetails = createAsyncThunk(
  "student/getStudentDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/std/" +
          "/studentId/" +
          studentId +
          "/getStudentDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//Student Update
export const updateStudentDetails = createAsyncThunk(
  "student/updateStudentDetails",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        orgId +
        "/student/" +
        studentId +
          "/updateStudentDetails",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const userSlice  = createSlice({
    name: 'user',
    initialState: {
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
      token:localStorage.getItem('token')?localStorage.getItem('token'):null,
      studentData: []
    },
    reducers: {
      clearState:(state)=>{
        state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    }},
    extraReducers:{
      [loginUser.fulfilled]: (state, { payload }) => {
        console.log("printing payload", payload);
        state.isFetching = false;
        state.isSuccess = true;
        state.token=payload.Token
        return state;
      },
      [loginUser.rejected]: (state, { payload }) => {
        console.log("payload", payload);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [loginUser.pending]: (state) => {
        state.isFetching = true;
      },
      [getStudentDetails.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.studentData=payload
        return state;
      },
      [getStudentDetails.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getStudentDetails.pending]: (state) => {
        state.isFetching = true;
      },
      [updateStudentDetails.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess=true
        return state;
      },
      [updateStudentDetails.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [updateStudentDetails.pending]: (state) => {
        state.isFetching = true;
      },
  
  }})


  export const { clearState } = userSlice.actions;

export const userSliceFunction = (state) => state.user;