import React from 'react'
import './FeedbackPopupStyle.css'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import TextArea from '../../globalComponents/textArea/TextArea'

const FeedbackPopup = ({title, question,lable,}) => {
    return (
        <div className="popupOuter">
            <div className="feedbackPopupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">{title}</p>
                    <img src="../assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody">
                    {question && <p className="question">{question}</p>}
                    <TextArea labelName={lable} placeholder={'Type Here ...'} isImportant={true}/>
                </div>
                <div className="popupFooter flexEnd">
                    <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Cancel'}/>
                    <PrimaryButton classname={'successBtn'} children={'Submit'}/>
                </div>
            </div>
        </div>
    )
}

export default FeedbackPopup