import React, { useEffect, useState } from 'react'
import './LoginStyle.css'
import { useDispatch, useSelector } from "react-redux";
import ErrorToast from '../../components/Toast/ErrorToast';
import { loginUser, userSliceFunction, clearState } from '../../reducers/userSlice';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../components/globalComponents/PageLoader/PageLoader';
import {COMMON_TEXT} from "../../helper/constants/TextConstant"


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { isSuccess, isFetching,isError,errorMessage } = useSelector(userSliceFunction)
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [username, setusername] = useState("")
  const [password, setPassword] = useState("")
  const [validateEmail, setEmailValidation] = useState(false);
  const [validatePassword, setPasswordValidation] = useState(false);
  
  console.log(isFetching, "11222");


  const handleEmailChange = (e) => {
    var mEmailAddress = e?.target?.value;
    setusername(e?.target?.value);
    var filter =
    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (mEmailAddress === "") {
      setEmailValidation(true);
    } else if (!filter.test(mEmailAddress)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
  }
  const onChangePassword = (event) => {
    var mPassword = event.target.value;
    setPassword(event.target.value);
    if (mPassword === "") {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username && password && username.length > 0 && password.length > 0) {
      var data = {
        email: username,
        password: password,
      };
      dispatch(loginUser(data));
      
    } else {
      setIsShowError(true);
      setErrMessage("Both fields must not be empty !");
      // localStorage.removeItem("token");
    }
  };

  const EnterSubmit = (e) => {
    if(e.key === 'Enter'){
      if (username && password && username.length > 0 && password.length > 0) {
        var data = {
          email: username,
          password: password,
        };
        dispatch(loginUser(data));
        
      } else {
        setIsShowError(true);
        setErrMessage("Both fields must not be empty !");
        // localStorage.removeItem("token");
      }
    }
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState())
      navigate('/', { exact: true })
       
    }
    if(isError){
      setIsShowError(true);
      setErrMessage(errorMessage)
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isSuccess,isError])



  return (


    <div className="loginContainer">
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="loginContainerLeft">
        <img src="../assets/img/loginImg.png" alt="" />
        <h1 className="heading">Learning Management System</h1>
        {/* <p className="subTitleText">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id urna id enim placerat viverra eu vel nibh. </p> */}
      </div>
      <div className="loginContainerRight">
        <img src="../assets/icons/muLogoBlack.svg" alt="" />
        <p className="welcomeText">Welcome Back</p>
        <p className="welcomeSubTitle">publishing industries for previewing layouts and visual mockups.</p>
        <div className="loginFormContainer">
          <div className="formGroup">
            <label htmlFor="email">Email Id</label>
            <input onKeyPress={EnterSubmit} className='loginInput' type="email" name="email" id="email" placeholder='john.doe@gmail.com' onChange={handleEmailChange} />
         
          </div>
          {validateEmail && (
                    <span className="msg-error" id="nameValidation">
                      {COMMON_TEXT.emailAlert}
                    </span>
                  )}
          <div className="formGroup mt5">
            <label htmlFor="password">Password</label>
            <input onKeyPress={EnterSubmit} className='loginInput' type="password" name="password" id="password" placeholder='•••••••••' onChange={onChangePassword} />
          </div>
          {validatePassword && (
                    <span className="msg-error" id="passValidation">
                      {COMMON_TEXT.passwordAlert}
                    </span>
                  )}
          {/* <p className="frogetLink">Forget Password?</p> */}
          <button className="signInButtton" type='button' onClick={handleSubmit}>Sign in</button>
          {/* <button className="signInwthGoogle"> <img src="../assets/icons/googleIcon.svg" alt="" /> Sign up with Google</button> */}
          <div className="copyrightText">
            <p>Copyright @masters' union</p> |
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login