import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";


let orgId;
let token;
let studentId;
let email;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  studentId = localStorage.getItem("studentId");
  email = localStorage.getItem("Email");

  //   programId= localStorage.getItem("programId");
};


//getCategories
export const getCategories = createAsyncThunk(
  "helpDeskData/getCategories",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        orgId +
        "/getCategories",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


//getSpecificCategories

export const getSpecificCategories = createAsyncThunk(
  "helpDeskData/getSpecificCategories",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        orgId + "/category/" +
        id + "/getSpecificCategories",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//getSpecificEnquiry
export const getSpecificEnquiry = createAsyncThunk(
  "helpDeskData/getSpecificEnquiry",
  async (status, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        studentId + "/getSpecificEnquiry",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();


      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//postTicketData
export const postTicketData = createAsyncThunk(
  "helpDeskData/postTicketData",
  async ({ category_id, sub_category_id, subject, description }, thunkAPI) => {
    setTokenValues();
    const payload = {
      "category_id": category_id,
      "sub_category_id": sub_category_id,
      "studentId": studentId,
      "subject": subject,
      "email": email,
      "description": description,
      "entity": "Mastercamps",
      "organizationId": orgId
    }
    console.log(payload);
    try {
      // console.log(obj, "new obj");

      const response = await fetch(
        environment.baseURL + `/api/std/${studentId}/addNewEnquiry`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//updateEnquiryByStudent 

export const updateEnquiryByStudent = createAsyncThunk(
  "helpDeskData/updateEnquiryByStudent",
  async ({ id, feedbackVal }, thunkAPI) => {
    setTokenValues();
    const payload = {
      "studentFeedback": feedbackVal,
      status: "resolved"
    }
    console.log(payload);
    try {
      const response = await fetch(
        environment.baseURL + `/api/std/${orgId}/${studentId}/enquiry/${id}/updateEnquiryByStudent`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//getSpecificEnquiryDetails

export const getSpecificEnquiryDetails = createAsyncThunk(
  "helpDeskData/getSpecificEnquiryDetails",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        `${studentId}` +
        "/enquiry/" + id +
        "/getSpecificEnquiryDetails",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();


      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//userResponse  
export const userResponse = createAsyncThunk(
  "helpDeskData/userResponse",
  async ({ enquiryId, message }, thunkAPI) => {
    setTokenValues();
    const payload = {
      "userResponseId": studentId,
      "enquiryId": enquiryId,
      "organizationId": orgId,
      "organizationStudentId": studentId,
      date: new Date(),
      reply: message
    }
    console.log(payload);
    try {
      // console.log(obj, "new obj");

      const response = await fetch(
        environment.baseURL + `/api/std/${studentId}/userResponse`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//getUserResponse

export const getUserResponse = createAsyncThunk(
  "helpDeskData/getUserResponse",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/std/" +
        `${studentId}` +
        "/enquiry/" + id +
        "/getUserResponse",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();


      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const helpDeskSlice = createSlice({
  name: 'helpDeskData',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    categoriesData: [],
    subCategoriesData: [],
    specificEnquiryData: [],
    specificEnquiryDetailsData: null,
    userResponseData: [],
    isChat: false,
    isFeedback:false,
    submittedTicket: false
  },

  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.submittedTicket = false;
      // state.isChat = false;
      return state;

    }
  },

  extraReducers: {
    [getCategories.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.categoriesData = payload
      return state;
    },
    [getCategories.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },

    [getCategories.pending]: (state) => {
      state.isFetching = true;
    },
    [getSpecificCategories.fulfilled]: (state, { payload }) => {

      state.subCategoriesData = payload
      state.isFetching = false;

      return state;
    },
    [getSpecificCategories.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificCategories.pending]: (state) => {
      state.isFetching = true;
    },
    [getSpecificEnquiry.fulfilled]: (state, { payload }) => {
      console.log(payload);
      // state.isFeedback = !state.isFeedback;
      state.specificEnquiryData = payload
      state.isFetching = false;

      return state;
    },
    [getSpecificEnquiry.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificEnquiry.pending]: (state) => {
      state.isFetching = true;
    },
    [postTicketData.fulfilled]: (state, { payload }) => {
      state.submittedTicket = true;
      state.isFetching = false;
      state.isSuccess = true;

      return state;
    },
    [postTicketData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [postTicketData.pending]: (state) => {
      state.isFetching = true;
    },

    [updateEnquiryByStudent.fulfilled]: (state, { payload }) => {
       state.isFeedback = !state.isFeedback;
      state.isFetching = false;
      state.isSuccess = true;

      return state;
    },
    [updateEnquiryByStudent.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateEnquiryByStudent.pending]: (state) => {
      state.isFetching = true;
    },

    [getSpecificEnquiryDetails.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.specificEnquiryDetailsData = payload
      state.isFetching = false;

      return state;
    },
    [getSpecificEnquiryDetails.rejected]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificEnquiryDetails.pending]: (state) => {
      state.isFetching = true;
    },
    [userResponse.fulfilled]: (state, { payload }) => {
      state.isChat = !state.isChat;
      state.isFetching = false;
      state.isSuccess = true;

      return state;
    },
    [userResponse.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [userResponse.pending]: (state) => {
      state.isFetching = true;
    },

    [getUserResponse.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.userResponseData = payload
      return state;
    },
    [getUserResponse.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },

    [getUserResponse.pending]: (state) => {
      state.isFetching = true;
    },








  }
}



)

export const { clearState } = helpDeskSlice.actions;

export const helpDeskSliceData = (state) => state.helpDeskData;