import React, { useState } from "react";
import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
import "./CreateClubStyle.css";
import TextField from "../../../components/globalComponents/textFields/TextField";
import TextArea from "../../../components/globalComponents/textArea/TextArea";
import Dropdown from "../../../components/globalComponents/dropdown/Dropdown";
import ConfirmationPopup from "../../../components/popups/confirmationPopup/ConfirmationPopup";
import { useNavigate } from "react-router-dom";
import UploadImagePopup from "../../../components/popups/uploadImgPopup/UploadImagePopup";
// import UploadBox from '../../../components/feedbackComponents/UploadBox/UploadBox'
const CreateClub = () => {
  const [pageToggler, setPageToggler] = useState(0); // 0=details, 1=document, 2=members
  const [popupToggler, setPopupToggler] = useState(false);
  const navigate = useNavigate();

  const [clubName, setClubName] = useState("");
  const [clubMission, setClubMission] = useState("");
  const [clubVision, setClubVision] = useState("");
  // const [clubType, setClubType] = useState("");
  const [clubDescription, setClubDescription] = useState("");
  // const [clubReportDocuments, setClubReportDocuments] = useState([]);

  return (
    <>
    <UploadImagePopup/>
      <ConfirmationPopup
        title={"Club Request Sent"}
        boldText={"You have successfully created a new club."}
        text={" Please view the 'Club Response' to know the approval status."}
        show={popupToggler}
        handleClose={setPopupToggler}
        btnText={"Continue"}
        btnType={"successBtn"}
      />
      <div className="pageHeaderType3">
        <div className="backBtn" onClick={() => navigate(-1)}>
          <img src="../assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        <div className="formTogglers">
          <div
            onClick={() => setPageToggler(0)}
            className={`formToggler ${pageToggler === 0 && "active"}`}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5 2.29199C6.24281 2.29199 2.79167 5.74313 2.79167 10.0003C2.79167 14.2575 6.24281 17.7087 10.5 17.7087C14.7572 17.7087 18.2083 14.2575 18.2083 10.0003C18.2083 5.74313 14.7572 2.29199 10.5 2.29199ZM1.54167 10.0003C1.54167 5.05277 5.55245 1.04199 10.5 1.04199C15.4476 1.04199 19.4583 5.05277 19.4583 10.0003C19.4583 14.9479 15.4476 18.9587 10.5 18.9587C5.55245 18.9587 1.54167 14.9479 1.54167 10.0003Z"
                fill="#737373"
              />
            </svg>
            <p>Club Details</p>
          </div>
          <div
            onClick={() => setPageToggler(1)}
            className={`formToggler ${pageToggler === 1 && "active"}`}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5 2.29199C6.24281 2.29199 2.79167 5.74313 2.79167 10.0003C2.79167 14.2575 6.24281 17.7087 10.5 17.7087C14.7572 17.7087 18.2083 14.2575 18.2083 10.0003C18.2083 5.74313 14.7572 2.29199 10.5 2.29199ZM1.54167 10.0003C1.54167 5.05277 5.55245 1.04199 10.5 1.04199C15.4476 1.04199 19.4583 5.05277 19.4583 10.0003C19.4583 14.9479 15.4476 18.9587 10.5 18.9587C5.55245 18.9587 1.54167 14.9479 1.54167 10.0003Z"
                fill="#737373"
              />
            </svg>
            <p>Upload Documents</p>
          </div>
          <div
            onClick={() => setPageToggler(2)}
            className={`formToggler ${pageToggler === 2 && "active"}`}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5 2.29199C6.24281 2.29199 2.79167 5.74313 2.79167 10.0003C2.79167 14.2575 6.24281 17.7087 10.5 17.7087C14.7572 17.7087 18.2083 14.2575 18.2083 10.0003C18.2083 5.74313 14.7572 2.29199 10.5 2.29199ZM1.54167 10.0003C1.54167 5.05277 5.55245 1.04199 10.5 1.04199C15.4476 1.04199 19.4583 5.05277 19.4583 10.0003C19.4583 14.9479 15.4476 18.9587 10.5 18.9587C5.55245 18.9587 1.54167 14.9479 1.54167 10.0003Z"
                fill="#737373"
              />
            </svg>
            <p>Add Members</p>
          </div>
        </div>
        <PrimaryButton
          children={pageToggler === 2 ? "Send for Approval" : "Save as Draft"}
          classname={`primaryBtn ${pageToggler === 2 && "successBtn"}`}
          onClick={() => setPopupToggler(true)}
        />
      </div>
      <div className="createClubContainer">
        <div
          className="createClubContainerOne"
          style={pageToggler === 0 ? null : { display: "none" }}
        >
          <div className="createClubHeader">
            <div className="addClubBackgroundImg">
              <img
                src="https://images.pexels.com/photos/46160/field-clouds-sky-earth-46160.jpeg?auto=compress&cs=tinysrgb&w=1600"
                alt=""
                className="clubBackgroundImg"
              />
            </div>
            <div className="clubProfile">
              <img
                src="https://images.pexels.com/photos/13187759/pexels-photo-13187759.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
                alt=""
                className="clubProfilePhoto"
              />
              <img
                src="../assets/icons/camera-01.svg"
                alt=""
                className="addProfilePhoto"
              />
            </div>
          </div>
          <form className="formOuter">
            <TextField
              labelName={"Club Name"}
              inputType={"text"}
              placeholder={"Create club"}
              value={clubName}
              onChange={(e) => setClubName(e.target.value)}
            />
            <TextField
              labelName={"Mission of your Club"}
              inputType={"text"}
              placeholder={"Mission of your Club"}
              value={clubMission}
              onChange={(e) => setClubMission(e.target.value)}
            />
            <TextField
              labelName={"Vision of your Club"}
              inputType={"text"}
              placeholder={"Vision of your Club"}
              value={clubVision}
              onChange={(e) => setClubVision(e.target.value)}
            />
            <Dropdown label={"ClubType"} />
            <TextArea
              labelName={"Why do you want to form the club?"}
              placeholder={"Reason for forming the club"}
              value={clubDescription}
              onChange={(e) => setClubDescription(e.target.value)}
            />
          </form>
          <div className="createClubContainerFooter flexEnd">
            <PrimaryButton classname={"secondaryBtn"} children={"Next"} />
          </div>
        </div>
        <div
          className="createContainerSecond"
          style={pageToggler === 1 ? null : { display: "none" }}
        >
          <div className="createContainerSecondInner">
            <div className="createContainerTableOuter">
              <table
                className="createClubTable"
                cellSpacing={0}
                cellPadding={0}
              >
                <thead>
                  <tr>
                    <th>Club Report</th>
                    <th>Size</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Program in Finance.pdf</td>
                    <td>140 KB</td>
                    <td>5 July 2020</td>
                    <td style={{ textAlign: "right" }}>
                      <img
                        src="../assets/icons/trash-01.svg"
                        alt=""
                        className="deleteIcon"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="createClubContainerFooter">
            <PrimaryButton
              classname={"darkSecondaryOutlineBtn"}
              children={"Back"}
            />
            <PrimaryButton classname={"secondaryBtn"} children={"Next"} />
          </div>
        </div>
        <div
          className="createContainerThird"
          style={pageToggler === 2 ? null : { display: "none" }}
        >
          <div className="filterBar">
            <div className="searchBox">
              <img src="../assets/icons/search-sm.svg" alt="" />
              <input
                type="text"
                className="searchInput"
                placeholder="Search ..."
              />
            </div>
            <div className="filterDropdwon">
              <Dropdown />
              <Dropdown />
              <Dropdown />
            </div>
          </div>
          <div className="addMembersTableOuter">
            <table className="addMemberTable" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th>Students</th>
                  <th>Course</th>
                  <th>Cohort</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Anand Krishnan</td>
                  <td>Applied Finance</td>
                  <td>Cohort - 1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="createClubContainerFooter">
            <PrimaryButton
              classname={"darkSecondaryOutlineBtn"}
              children={"Back"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateClub;
