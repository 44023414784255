
import DatePicker from 'react-date-picker';
import "./CustomDatePickerStyle.css"

const CustomDatePicker=({isFilled,date,setDate,minDate})=> { 

    return (
      
        <div className='' style={{width:'100%'}}>
            <DatePicker className={`${isFilled ? 'isFilled': ''}`}  onChange={setDate} minDate={minDate} value={date} calendarAriaLabel clearIcon calendarIcon format='dd/MM/yyyy'/>
    </div>

    );
}

export default CustomDatePicker
