import React from 'react'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import TextField from '../../../components/globalComponents/textFields/TextField'
import TextArea from '../../../components/globalComponents/textArea/TextArea'
import Dropdown from '../../../components/globalComponents/dropdown/Dropdown'
import { useNavigate } from "react-router-dom";
import './EditClubStyle.css'
const EditClub = () => {

    const navigate = useNavigate();


    return (
        <>
            <div className="pageHeaderType3">
                <div className="backBtn"  onClick={() => navigate('/createClub')}>
                    <img src="../assets/icons/back-btn.svg" alt="" />
                    Back to Page
                </div>
                <div className="formTogglers">
                    <div className="formToggler active">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337ZM14.4001 7.73393C14.6442 7.48986 14.6442 7.09413 14.4001 6.85005C14.156 6.60597 13.7603 6.60597 13.5162 6.85005L8.95817 11.4081L6.90011 9.35005C6.65603 9.10597 6.26031 9.10597 6.01623 9.35005C5.77215 9.59413 5.77215 9.98986 6.01623 10.2339L8.51623 12.7339C8.76031 12.978 9.15604 12.978 9.40011 12.7339L14.4001 7.73393Z" fill="#1C7C54" />
                        </svg>

                        <p>Club Details</p>
                    </div>
                    <div className="formToggler active">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337ZM14.4001 7.73393C14.6442 7.48986 14.6442 7.09413 14.4001 6.85005C14.156 6.60597 13.7603 6.60597 13.5162 6.85005L8.95817 11.4081L6.90011 9.35005C6.65603 9.10597 6.26031 9.10597 6.01623 9.35005C5.77215 9.59413 5.77215 9.98986 6.01623 10.2339L8.51623 12.7339C8.76031 12.978 9.15604 12.978 9.40011 12.7339L14.4001 7.73393Z" fill="#1C7C54" />
                        </svg>

                        <p>Club Members</p>
                    </div>
                    <div className="formToggler active">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337ZM14.4001 7.73393C14.6442 7.48986 14.6442 7.09413 14.4001 6.85005C14.156 6.60597 13.7603 6.60597 13.5162 6.85005L8.95817 11.4081L6.90011 9.35005C6.65603 9.10597 6.26031 9.10597 6.01623 9.35005C5.77215 9.59413 5.77215 9.98986 6.01623 10.2339L8.51623 12.7339C8.76031 12.978 9.15604 12.978 9.40011 12.7339L14.4001 7.73393Z" fill="#1C7C54" />
                        </svg>

                        <p>Comments</p>
                    </div>
                </div>
                <div className="successTag">Approved</div>
                {/* <div className="dangerTag">Rejected</div>
      <div className="primaryTag">Pending</div> */}
            </div>
            <div className="createClubContainer">
                <div className="createClubContainerOne" style={{ display: 'none' }}>
                    <div className="createClubHeader">
                        <div className="addClubBackgroundImg">
                            <img src="https://images.pexels.com/photos/46160/field-clouds-sky-earth-46160.jpeg?auto=compress&cs=tinysrgb&w=1600" alt=""
                                className='clubBackgroundImg' />

                        </div>
                        <div className="clubProfile">
                            <img src="https://images.pexels.com/photos/13187759/pexels-photo-13187759.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load" alt="" className='clubProfilePhoto' />
                            <img src="../assets/icons/camera-01.svg" alt="" className='addProfilePhoto' />
                        </div>
                    </div>
                    <form className='formOuter'>
                        <TextField labelName={'Club Name'} inputType={'text'} placeholder={'Create club'} />
                        <TextField labelName={'Mission of your Club'} inputType={'text'} placeholder={'Mission of your Club'} />
                        <TextField labelName={'Vision of your Club'} inputType={'text'} placeholder={'Vision of your Club'} />
                        <Dropdown label={'ClubType'} />
                        <TextArea labelName={'Why do you want to form the club?'} placeholder={'Reason for forming the club'} />
                    </form>
                    <div className="createClubContainerFooter flexEnd">
                        <PrimaryButton classname={'secondaryBtn'} children={'Next'} />
                    </div>
                </div>
                <div className="createContainerSecond" style={{ display: 'none' }}>
                    <div className="createContainerSecondInner">
                        <div className="createContainerTableOuter">
                            <table className="createClubTable" cellSpacing={0} cellPadding={0}>
                                <thead>
                                    <tr>
                                        <th>Club Report</th>
                                        <th>Size</th>
                                        <th>Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Program in Finance.pdf</td>
                                        <td>140 KB</td>
                                        <td>5 July 2020</td>
                                        <td style={{ textAlign: 'right' }}><img src="../assets/icons/trash-01.svg" alt="" className='deleteIcon' /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="createClubContainerFooter">
                        <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Back'} />
                        <PrimaryButton classname={'secondaryBtn'} children={'Next'} />
                    </div>
                </div>
                <div className="createContainerForth">
                    <div className="conversationBoxOuter">
                    <div className="coversationBoxLeft">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className='userImg' />
                        <div className="messageBoxOuter">
                        <div className="messageBox">
                            <p className="message">
                                The Development Monitoring and Evaluation Office (DMEO) was constituted in September 2015 by 
                                merging the erstwhile Program Evaluation Office (PEO) and the was constituted in September 2015 (DMEO) 
                                was constituted in September 2015 by 
                            </p>
                        </div>
                        <p className="time">3:40 am</p>
                        </div>
                    </div>
                    <div className="coversationBoxRight">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="" className='userImg' />
                        <div className="messageBoxOuter">
                        <div className="messageBox">
                            <p className="message">
                                The Development Monitoring and Evaluation Office (DMEO) was constituted in September 2015 by 
                                merging the erstwhile Program Evaluation Office (PEO) and the was constituted in September 2015 (DMEO) 
                                was constituted in September 2015 by 
                            </p>
                        </div>
                        <p className="time">3:40 am</p>
                        </div>
                    </div>
                    </div>
                    <div className="messageInputBox">
                        <input type="text" className="messageInput" placeholder='Send Message ...'/>
                        <PrimaryButton classname={'primaryBtn'} children={'Send'}/>
                    </div>
                </div>

            </div>
        </>
    )
}

export default EditClub