import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import TextArea from '../../globalComponents/textArea/TextArea'
import './SessionFeedbackPopoupStyle.css'
const SessionFeedbackPopoup = () => {
    return (
        <div className="popupOuter">
            <div className="popupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">Feedback Form</p>
                    <img src="../assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody sessionFeedbackBody">
                    <div className="formGroup">
                        <label htmlFor="">
                            How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?
                            <span className="impMark">*</span>
                        </label>
                        <div className="feedbackRadioGroup">
                            <p className="label">Poor</p>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test1" name="radio-group" />
                                <label for="test1">1</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test2" name="radio-group" />
                                <label for="test2">2</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test3" name="radio-group" />
                                <label for="test3">3</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test4" name="radio-group" />
                                <label for="test4">4</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test5" name="radio-group" />
                                <label for="test5">5</label>
                            </div>
                            <p className="label">Outstanding</p>
                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">
                            How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?
                            <span className="impMark">*</span>
                        </label>
                        <div className="feedbackRadioGroup flexStart">

                            <div className="radioBtnOuter">
                                <input type="radio" id="yes" name="radio-group" />
                                <label for="yes">Yes</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="no" name="radio-group" />
                                <label for="no">No</label>
                            </div>


                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">
                            How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?
                            <span className="impMark">*</span>
                        </label>
                        <div className="feedbackRadioGroup flexStart">

                            <div className="radioBtnOuter">
                                <input type="radio" id="yes" name="radio-group" />
                                <label for="yes">Yes</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="no" name="radio-group" />
                                <label for="no">No</label>
                            </div>


                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">
                            How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?
                            <span className="impMark">*</span>
                        </label>
                        <div className="feedbackRadioGroup flexStart">

                            <div className="radioBtnOuter">
                                <input type="radio" id="yes" name="radio-group" />
                                <label for="yes">Yes</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="no" name="radio-group" />
                                <label for="no">No</label>
                            </div>


                        </div>
                    </div>
                    <div className="formGroup">
                        <label htmlFor="">
                            How was your todays’ interaction with the Coach. Rate your satisfaction on the scale of 1-5, 5 being the highest ?
                            <span className="impMark">*</span>
                        </label>
                        <div className="feedbackRadioGroup">
                            <p className="label">Poor</p>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test1" name="radio-group" />
                                <label for="test1">1</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test2" name="radio-group" />
                                <label for="test2">2</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test3" name="radio-group" />
                                <label for="test3">3</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test4" name="radio-group" />
                                <label for="test4">4</label>
                            </div>
                            <div className="radioBtnOuter">
                                <input type="radio" id="test5" name="radio-group" />
                                <label for="test5">5</label>
                            </div>
                            <p className="label">Outstanding</p>
                        </div>
                    </div>
                    <div className="formGroup">
                        <TextArea labelName={'Highlight a skill/quality of your Coach that you wish to imbibe?'} isImportant={true} placeholder={'Enter your Suggestions...'} />
                    </div>
                    <div className="formGroup">
                        <TextArea labelName={'Do you wish to share any suggestions?'} isImportant={true} placeholder={'Enter your Suggestions...'} />
                    </div>
                </div>
                <div className="popupFooter flexEnd">
                    <PrimaryButton classname={'primaryBtn'} children={'Submit'} />
                </div>
            </div>
        </div>
    )
}

export default SessionFeedbackPopoup