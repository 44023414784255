import React from "react";
import "./MyCoursesStyle.css";
import Button from "../../components/globalComponents/buttons/primaryButton/PrimaryButton";
// import Dropdown from "../../components/globalComponents/dropdown/Dropdown";
import ProgressBar from "../../components/globalComponents/progressBar/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { lmsSliceData, getCourseByStudentId } from "../../reducers/lmsSlice";
import { useEffect } from "react";
import PageLoader from "../../components/globalComponents/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";

const MyCourses = () => {
  const disptach = useDispatch();
  const navigate = useNavigate();
  const { isFetching, getStudentCourseData } =
    useSelector(lmsSliceData);

  // const [sessionPercentage, setSessionPercentage] = useState(0);

  useEffect(() => {
    disptach(getCourseByStudentId());
    // eslint-disable-next-line
  }, []);

  const viewSession = (res) => {
    navigate(`/courseDetails?courseId=${res.CourseRoster?.id}`, {
      exact: true,
    });
  };

  const getPercentage = (data) => {
    let newData=data?.CourseRoster?.CourseSessions
    let allSessData = data?.CourseRoster?.CourseSessions?.filter(
        (data) => data.isCompleted === true
      )
    console.log(allSessData);

    return newData?.length > 0 ? Math.round(
      (allSessData.length /
       data?.CourseRoster?.CourseSessions.length) *100
    ):0;
  };
  return (
    <>
      {isFetching && <PageLoader />}
      <div className="myCoursesOuter">
        <div className="myCoursesHeader">
          <p className="heading">My Courses</p>
          {/* <Dropdown /> */}
        </div>
        {getStudentCourseData?.length > 0 &&
          getStudentCourseData?.map((res, i) => (
            <div className="myCoursesBody" key={i}>
              <div className="myCourseCard">
                <div className="myCourseCardHeader">
                  <p className="courseTitle">{res.CourseRoster.courseName}</p>
                  <Button
                    children={"View Sessions"}
                    classname={"primaryBtn"}
                    isdisabled={false}
                    onClick={() => viewSession(res)}
                  />
                </div>
                <div className="myCourseCardBody">
                  <div className="myCourseSessionDetails">
                    <p>Sessions {res?.CourseRoster?.CourseSessions?.length}</p>
                    <p>{getPercentage(res)}%</p>
                  </div>
                  <ProgressBar width={`${getPercentage(res)}%`} />
                </div>
              </div>
              {/* <div className="myCourseCard">
              <div className="myCourseCardHeader">
                  <p className="courseTitle">Analyzing Financial Statements</p>
                  <Button children={'View Sessions'} classname={'secondaryBtn'} isdisabled={false}/>
              </div>
              <div className="myCourseCardBody">
                <div className="myCourseSessionDetails">
                  <p>Sessions 12</p>
                  <p>0%</p>
                </div>
                  <ProgressBar width={'0%'}/>
              </div>
          </div> */}
            </div>
          ))}
      </div>
    </>
  );
};

export default MyCourses;
