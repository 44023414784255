import React from 'react'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import CustomDatePicker from '../../../components/globalComponents/customDatePicker/CustomDatePicker'
import Dropdown from '../../../components/globalComponents/dropdown/Dropdown'
import TextArea from '../../../components/globalComponents/textArea/TextArea'
import TextField from '../../../components/globalComponents/textFields/TextField'
import './CreateMeetingStyle.css'

const CreateMeeting = () => {
    return (
        <>
            <div className="pageHeader">
                <p className="componentHeading"></p>
                <div className="pageTogglers">
                    <div className="pageToggler active">

                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2249 2.05798C15.3895 0.893432 17.2776 0.893433 18.4421 2.05798C19.6067 3.22254 19.6067 5.11065 18.4421 6.2752L7.43688 17.2804C7.42469 17.2926 7.41265 17.3047 7.40076 17.3166C7.1908 17.5268 7.02544 17.6923 6.82998 17.8212C6.65759 17.9348 6.47064 18.0247 6.2742 18.0883C6.05144 18.1604 5.81888 18.1861 5.52359 18.2188C5.50688 18.2206 5.48996 18.2225 5.47283 18.2244L2.65252 18.5378C2.46382 18.5587 2.27581 18.4928 2.14156 18.3585C2.0073 18.2243 1.94136 18.0363 1.96232 17.8476L2.27569 15.0273C2.27759 15.0101 2.27947 14.9932 2.28132 14.9765C2.31398 14.6812 2.3397 14.4487 2.41181 14.2259C2.47541 14.0294 2.56526 13.8425 2.67893 13.6701C2.80782 13.4747 2.97334 13.3093 3.18351 13.0993C3.19542 13.0874 3.20747 13.0754 3.21967 13.0632L14.2249 2.05798ZM17.5582 2.94187C16.8819 2.26547 15.7852 2.26547 15.1088 2.94187L4.10355 13.9471C3.84069 14.21 3.77263 14.2822 3.72247 14.3583C3.6708 14.4366 3.62995 14.5216 3.60105 14.6109C3.57298 14.6976 3.5591 14.7959 3.51804 15.1653L3.29095 17.2091L5.33479 16.9821C5.70424 16.941 5.80251 16.9271 5.88921 16.899C5.97851 16.8701 6.06348 16.8293 6.14184 16.7776C6.21791 16.7275 6.29014 16.6594 6.553 16.3965L17.5582 5.39132C18.2346 4.71492 18.2346 3.61827 17.5582 2.94187Z"
                                fill="#262626" />
                        </svg>
                        Create Meeting
                    </div>
                </div>
                <div>

                </div>
            </div>
            <div className="createMeetingContainerOuter">
                <div className="createMeetingContainer">
                    <div className="createMeetingForm">
                        <TextField labelName={'Session Name'} placeholder={'Enter Session Name'} />
                        <CustomDatePicker label={'Proposed date of the session'} />
                        <Dropdown label={'Select Coach'}/>
                        <TextArea placeholder={'Write your Agenda of the session'} labelName={'Agenda'} />
                    </div>
                    <div className="createMeetingFooter">
                        <PrimaryButton classname={'primaryBtn'} children={'Submit'}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateMeeting