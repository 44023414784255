import React from 'react'
import './FileViewerStyle.css'
const FileViewer = (props) => {
  return (
    <div className="fileViewerOuter" onClick={()=>window.open(props.fileUrl)}>
        <img src="../assets/icons/zipFile.svg" alt="" />
        <div className="fileViewContent">
            <p className="fileName">{props.fileName}</p>
            {/* <p className="fileSize">15 MB ZIP</p> */}
        </div>
    </div>
  )
}

export default FileViewer