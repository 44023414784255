import React from "react";
// import FileViewer from '../../components/globalComponents/fileViewer/FileViewer'

const MeetDetails = () => {
  return (
    <>
      <div className="pageHeader">
        <div className="backBtn">
          <img src="../assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="leaveDetailsContainerOuter">
        <div className="leaveDetailsContainer">
          <div className="leaveDetailsHeader meetingDetailsHeader">
            <p className="heading">Design Sprint - 16</p>
            <p className="subDetails"> Booked On - 22/10/2022</p>
          </div>
          <div className="leaveDetailsTable">
            <div className="leaveDetailsTableElement">
              <p className="heading">Meeting Date</p>
              <p className="content">Multiple Leave </p>
            </div>
            <div className="leaveDetailsTableElement">
              <p className="heading">Meeting Start Time</p>
              <p className="content">4 PM</p>
            </div>
            <div className="leaveDetailsTableElement">
              <p className="heading">Meeting Duration</p>
              <p className="content">1 hour</p>
            </div>
            <div className="leaveDetailsTableElement">
              <p className="heading">Attendees</p>
              <p className="content">6</p>
            </div>
            <div className="leaveDetailsTableElement">
              <p className="heading">Tower</p>
              <p className="content">A </p>
            </div>
            <div className="leaveDetailsTableElement">
              <p className="heading">Room No.</p>
              <p className="content">3</p>
            </div>
          </div>

          <div className="missingTableContainer">
            <table
              className="missingLectureTable"
              cellPadding={0}
              cellSpacing={0}
            >
              <thead>
                <tr>
                  <th>Attendees List</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetDetails;
